import { combineReducers } from '@reduxjs/toolkit';
import UserData from './user-data/reducer';
import ShareProduct from './share-product/reducer';
import MyToko from './toko-data/reducer';
import Referral from './referral/reducer';
import { DESTROY_SESSION } from './user-data/actionTypes';
import Profile from './general/profile/reducer';
import Category from './category/reducer';
import CategoryDetail from './category_detail/reducer';
import SearchProduct from './search_product/reducer';
import SearchSort from './search_sort/reducer';
import SearchUtil from './search_util/reducer';
import ProductDetail from './product_detail/reducer';
import SupplierCategoryDetail from './supplier_category_detail/reducer';
import Supplier from './supplier_detail/reducer';
import AddressIntro from './address_intro/reducer';
import AddressEdit from './address_edit/reducer';
import AddressCreate from './address_create/reducer';
import Penalty from './penalty/reducer';
import PreCheckoutInit from './pre_checkout_init/reducer';
import PreCheckoutRequest from './pre_checkout_request/reducer';
import PreCheckout from './pre_checkout/reducer';
import RecommendationStore from './recommendation-store/reducer';
import CartStore from './cart/reducer';
import AddressCheckoutStore from './address_checkout/reducer';
import PreCheckoutNewStore from './checkout/reducer';

const appReducer = combineReducers({
  UserData,
  ShareProduct,
  MyToko,
  Referral,
  Profile,
  Category,
  CategoryDetail,
  SearchProduct,
  SearchSort,
  SearchUtil,
  ProductDetail,
  SupplierCategoryDetail,
  Supplier,
  AddressCreate,
  AddressEdit,
  AddressIntro,
  Penalty,
  PreCheckoutInit,
  PreCheckoutRequest,
  PreCheckout,
  RecommendationStore,
  CartStore,
  AddressCheckoutStore,
  PreCheckoutNewStore,
});
const rootReducer = (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
