import STORAGE from '@/constant/storage';

export const savePenalty = (payload: Address.LocationPenalty) => ({
  type: STORAGE.PENALTY,
  payload,
});

export const removePenalty = () => ({
  type: STORAGE.PENALTY,
  payload: null,
});
