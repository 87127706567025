import { PayloadAction } from '@reduxjs/toolkit';
import {
  SAVE_LIST_RECOMMENDATION_STORE,
  SAVE_DETAIL_RECOMMENDATION_STORE,
  SAVE_LIST_BUSINESS_TYPE,
} from './actionTypes';

const initialState = {
  listRecommendationStore: [],
  detailRecommendationStore: null,
  listBusinessType: [],
};

const RecommendationStoreData = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case SAVE_LIST_RECOMMENDATION_STORE:
      state = {
        ...state,
        listRecommendationStore: action.payload,
      };
      break;
    case SAVE_DETAIL_RECOMMENDATION_STORE:
      state = {
        ...state,
        detailRecommendationStore: action.payload,
      };
      break;
    case SAVE_LIST_BUSINESS_TYPE:
      state = {
        ...state,
        listBusinessType: action.payload,
      };
      break;
    default:
      state;
      break;
  }

  return state;
};

export default RecommendationStoreData;
