import { useCountdown } from '@/lib/hooks/utils';
import { Box, Text, Input, Button } from '@chakra-ui/react';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import BottomSheet from '../bottom-sheet';
import ButtonGlobal from '../button-global';
import PinInput from '../pin-input';

const PopupWaVerification: React.FC<PopupWaType.PopupWaProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, mode, onBack, withCloseButton } = props;
  const { countdown, resetCountdown } = useCountdown(30);

  const handleRequestOtp = () => {
    if (props.onSubmitWa) {
      props.onSubmitWa(() => {
        resetCountdown();
      });
    }
  };

  React.useEffect(() => {
    if (mode === 'input_otp') {
      resetCountdown();
    }
  }, [mode]);

  return (
    <BottomSheet
      isOpen={isOpen}
      closeOnEsc={false}
      variant={'bottomSheet'}
      onClose={props.onClose}
      withCloseButton={withCloseButton}
    >
      <BottomSheet.Title onBack={onBack}>
        {mode === 'input_wa' ? <Trans i18nKey={'verify_wa_btn'} /> : <Trans i18nKey={'common:insert_wa_otp'} />}
      </BottomSheet.Title>
      {mode === 'input_wa' ? (
        <BottomSheet.Body>
          <Box>
            <Text fontSize={'xs'}>
              <Trans i18nKey={'common:insert_wa_number'} />
            </Text>
          </Box>
          <Box paddingTop={2}>
            <Input size={'sm'} placeholder={t('wa_number')} onChange={props.onChangeText} />
          </Box>
          <Box marginTop={20}>
            <ButtonGlobal
              isLoading={props.loadingSubmitWa}
              disabled={props.disableBtn || props.loadingSubmitWa}
              onClick={handleRequestOtp}
            >
              <Trans i18nKey={'common:send_otp'} />
            </ButtonGlobal>
          </Box>
        </BottomSheet.Body>
      ) : (
        <BottomSheet.Body>
          <Box>
            <Text fontSize={'xs'} textAlign={'center'}>
              <Trans i18nKey={'common:send_otp_wa'} values={{ waNumber: props.phoneNumber }} />
            </Text>
          </Box>
          <Box display={'flex'} justifyContent="center">
            <PinInput value={props.myOtp} size={'lg'} onChange={props.onSaveOtp} />
          </Box>
          {countdown > 0 ? (
            <Box marginTop={'10'}>
              <Text color={'gray.light'} fontSize="xs" textAlign="center">
                <Trans i18nKey={'common:countdown_otp'} values={{ countdown }} />{' '}
              </Text>
            </Box>
          ) : (
            <Box marginTop={7}>
              <Text color={'gray.light'} fontSize={'xs'} textAlign={'center'}>
                <Trans i18nKey={'not_receive_verification_code'} />{' '}
                <Button
                  height={'0'}
                  fontSize={'xs'}
                  verticalAlign={'inherit'}
                  padding={'0'}
                  background={'transparent'}
                  color={'primary.default'}
                  onClick={handleRequestOtp}
                >
                  <Trans i18nKey={'common:send_again'} />{' '}
                </Button>{' '}
                <Trans i18nKey={'common:or'} />{' '}
                <Button
                  color={'primary.default'}
                  height={'0'}
                  fontSize={'xs'}
                  padding={'0'}
                  verticalAlign={'inherit'}
                  background={'transparent'}
                  onClick={onBack}
                >
                  <Trans i18nKey={'insert_other_number'} />
                </Button>
              </Text>
            </Box>
          )}

          <Box marginTop={20}>
            <ButtonGlobal
              disabled={props.isLoadingSubmitCode}
              isLoading={props.isLoadingSubmitCode}
              onClick={props.onVerifyOtp}
            >
              <Trans i18nKey={'common:verification'} />
            </ButtonGlobal>
          </Box>
        </BottomSheet.Body>
      )}
    </BottomSheet>
  );
};

export default PopupWaVerification;
