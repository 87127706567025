import { PayloadAction } from '@reduxjs/toolkit';
import { SAVE_REFERRAL } from './actionTypes';

interface ReferralReducerProps {
  referral: Admin.ReferralDetail | null;
}

const initialState: ReferralReducerProps = {
  referral: null,
};
//
const ReferralReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case SAVE_REFERRAL:
      state = {
        ...state,
        referral: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default ReferralReducer;
