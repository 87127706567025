import { PayloadAction } from '@reduxjs/toolkit';
import { SAVE_USER_DATA } from './actionTypes';

const initialState = {
  user: {},
};
//
const UserData = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case SAVE_USER_DATA:
      state = {
        ...state,
        user: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default UserData;
