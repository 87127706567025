import { ListSortProps } from '@/app/Admin/MyReferral/Popup/sort';
import { getReferral } from '@/lib/api/admin';
import {
  checkReferralCode,
  getReferralHistory,
  getReferralSummary,
  ParamHistoryReferral,
  submitMentor,
} from '@/lib/api/admin/referral';
import { RootState } from '@/store';
import { saveReferral } from '@/store/referral/action';
import { useToast } from '@chakra-ui/toast';
import { useInfiniteQuery, useQuery, useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEventLog from '@/lib/hooks/utils/useEventLog';

interface useMyReferralProps {
  enable?: boolean;
}

const useMyReferral = (props: useMyReferralProps) => {
  const dispatch = useDispatch();
  const { query } = useRouter();
  const { LOGS, writeLog } = useEventLog();
  const [periode] = React.useState<string>(
    `${
      new Date().getMonth() + 1 >= 10 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`
    }-${new Date().getFullYear()}`,
  );
  const { data: dataSummary } = useQuery<Admin.ResponseSummaryVoucherAll>(
    ['params', periode],
    () => getReferralSummary(periode),
    { enabled: props.enable },
  );
  const myReferral = useSelector((state: RootState) => state.Referral && state.Referral.referral);
  const [codeInput, setCode] = React.useState<string>('');
  const [selectedMentor, setSelectedMentor] = React.useState<Admin.MentorResponse | null>(null);
  const { mutate: mutationDetailCode } = useMutation(checkReferralCode);
  const { mutate: mutationSubmitCode } = useMutation(submitMentor);
  const { mutate: mutationGetOwnReferral } = useMutation(getReferral);
  const [fetchMentort, setFetchMentor] = React.useState<boolean>(false);
  const [showInvitePopup, setShowInvitePopup] = React.useState<boolean>(false);
  const [loadingSubmitMentor, setLoadingSubmitMentor] = React.useState<boolean>(false);
  const [param, setParam] = React.useState<ParamHistoryReferral>({
    page: 1,
    limit: 10,
  });
  const [selectedSSort, setSelectedSort] = React.useState<ListSortProps | null>(null);
  const [openPopupSort, setOpenPopupSort] = React.useState<boolean>(false);
  const toast = useToast();
  // const updateParam = (key: string, value?: string) => {
  //   setParam({ ...param, [key]: value });
  // };
  const getMyReferral = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useInfiniteQuery<Admin.ResponseReferralListAll>(
      ['referralHistory'],
      ({ pageParam = 1 }) => {
        return getReferralHistory({ ...query, page: pageParam });
      },
      {
        getNextPageParam: (lastPage) => {
          if (lastPage.current_page > lastPage.max_page) return;
          return lastPage.current_page + 1 ?? undefined;
        },
        cacheTime: 60 * 1000 * 10,
        staleTime: 60 * 1000,
      },
    );
  };

  const togglePopupSort = () => setOpenPopupSort((prevState) => !prevState);

  const handleFetchCode = () => {
    if (codeInput === '') return toast({ status: 'error', description: 'Kode referral tidak boleh kosong' });
    setFetchMentor(true);
    mutationDetailCode(codeInput, {
      onSuccess: (response) => {
        setSelectedMentor(response.data);
        setFetchMentor(false);
        toast({ status: 'success', description: response.message_client });
      },
      onError: () => {
        setFetchMentor(false);
      },
    });
  };

  const handleInputCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCode(value);
  };

  const removeSelectedMentor = () => setSelectedMentor(null);

  const toggleInvitePoup = () => setShowInvitePopup((prevState) => !prevState);

  const onSubmitMentor = () => {
    setLoadingSubmitMentor(true);
    const data = {
      code: codeInput,
    };

    writeLog(LOGS.CLICK.CLICK_REFERRAL_MENTOR, data);

    mutationSubmitCode(data, {
      onSuccess: () => {
        setLoadingSubmitMentor(false);
        setShowInvitePopup(false);
        getReferralDetail(true);
      },
      onError: () => {
        setLoadingSubmitMentor(false);
      },
    });
  };

  const handleApiGetReferral = () => {
    mutationGetOwnReferral(undefined, {
      onSuccess: (response) => {
        if (response) {
          dispatch(saveReferral(response));
        }
      },
    });
  };

  const findSelectedSort = (order_by: string, order_dir: string) => {
    if (query.order_by === order_by && query.order_dir === order_dir) {
      return true;
    }
    return false;
  };

  const getReferralDetail = (isReload?: boolean) => {
    if (myReferral) {
      if (isReload) {
        return handleApiGetReferral();
      }
      return;
    }
    handleApiGetReferral();
  };

  return {
    dataSummary,
    getMyReferral,
    // updateParam,
    showInvitePopup,
    toggleInvitePoup,
    handleFetchCode,
    fetchMentort,
    handleInputCode,
    codeInput,
    selectedMentor,
    removeSelectedMentor,
    onSubmitMentor,
    loadingSubmitMentor,
    getReferralDetail,
    myReferral,
    togglePopupSort,
    openPopupSort,
    selectedSSort,
    setSelectedSort,
    setParam,
    param,
    findSelectedSort,
  };
};

export default useMyReferral;
