import { useLayout } from '@/lib/hooks/utils';
import { Box, Text, HStack } from '@chakra-ui/react';
import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';

interface AppBarInterface {
  children?: React.ReactNode;
  content?: JSX.Element;
  rightContent?: JSX.Element;
  title?: string;
  withoutBack: boolean | false;
  customBackPress?: () => void;
}

const AppBar: React.FC<AppBarInterface> = ({
  children,
  content,
  rightContent,
  title,
  withoutBack,
  customBackPress,
}) => {
  const { goBack } = useLayout();

  const withChildren = () => {
    return (
      <Box
        width={'full'}
        paddingStart={0}
        paddingEnd={0}
        borderBottom={'1px'}
        borderBottomColor={'light.gray'}
        position={'sticky'}
        top={0}
        bgColor={'white'}
        zIndex="overlay"
      >
        <HStack width={'full'} bgColor={'white'} paddingX={3} paddingTop={3} paddingBottom={3}>
          {withoutBack ? null : (
            <FiArrowLeft
              color={'black'}
              size={23}
              onClick={() => {
                if (customBackPress !== undefined) customBackPress();
                else goBack();
              }}
            />
          )}
          {title !== undefined ? (
            <Box flex={1} marginStart={3}>
              <Text fontSize="sm" textColor={'gray.dark'}>
                {title}
              </Text>
            </Box>
          ) : (
            content
          )}
          {rightContent !== undefined ? rightContent : null}
        </HStack>
        {children}
      </Box>
    );
  };

  const withoutChildren = () => {
    return (
      <HStack
        width={'full'}
        paddingX={3}
        paddingTop={3}
        paddingBottom={3}
        borderBottom={'1px'}
        borderBottomColor={'light.gray'}
        position={'sticky'}
        top={0}
        bgColor={'white'}
        zIndex="overlay"
      >
        {withoutBack ? null : (
          <FiArrowLeft
            color={'black'}
            size={23}
            onClick={() => {
              if (customBackPress !== undefined) customBackPress();
              else goBack();
            }}
          />
        )}
        {title !== undefined ? (
          <Box flex={1} marginStart={3}>
            <Text fontSize="sm" textColor={'gray.dark'}>
              {title}
            </Text>
          </Box>
        ) : (
          content
        )}
        {rightContent !== undefined ? rightContent : null}
      </HStack>
    );
  };

  return children !== undefined ? withChildren() : withoutChildren();
};

export default AppBar;
