import { format } from 'date-fns';
import packageInfo from '../package.json';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const appVersion = packageInfo.version;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const currencyFormatter = (value: number, nation = 'id-ID', currency = 'IDR'): string => {
  return new Intl.NumberFormat(nation, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const dateFormatter = (date: Date | string, dateFormat = 'dd-MM-yyyy'): string => {
  return format(new Date(date), dateFormat);
};

export const numberFormater = (value: number, decimal = 0): string => {
  return new Intl.NumberFormat('id-ID', {
    style: 'decimal',
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(value);
};

export const parseCurrency = (data: string): string => {
  const group = new Intl.NumberFormat('id-ID').format(1111).replace(/1/g, '');
  const decimal = new Intl.NumberFormat('id-ID').format(1.1).replace(/1/g, '');
  let reversedVal = data.replace(new RegExp('\\' + group, 'g'), '');
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
  return Number.isNaN(reversedVal) ? '0' : reversedVal.replace('Rp ', '');
};

export const verifyWaFormat = (phone: string) => {
  phone = phone.replace(/^0/, '62');
  return phone;
};

export const handleParamUrl = (url: string, params: any) => {
  if (params) {
    let url2 = url;
    Object.keys(params).map((key, index) => {
      if (params[key] && params[key] !== '') {
        url2 += `${index === 0 ? '?' : '&'}${key}=${params[key]}`;
      }
    });
    return url2;
  } else {
    return url;
  }
};

export const parserNumber = (value: string): string => {
  return value.replace(/\./g, '');
};

export const setCapitalFirstLetter = (text?: string) => {
  if (text) {
    const capital = text.charAt(0).toUpperCase();
    const notCapital = text.slice(1);
    return `${capital}${notCapital}`;
  }
  return '';
};

export const isMatchUrl = (link: string) => {
  if (link && typeof link === 'string') {
    const expression = '(http|ftp|https)://[\\w-]+(\\.[\\w-]+)+([\\w-.,@?^=%&:/~+#-]*[\\w@?^=%&;/~+#-])?';
    const regex = new RegExp(expression);
    const result = link.match(regex);
    if (result) {
      return true;
    }
    return false;
  }
};

export const isMatchHttps = (link: string) => {
  if (link && typeof link === 'string') {
    const expression = '(https)://[\\w-]+(\\.[\\w-]+)+([\\w-.,@?^=%&:/~+#-]*[\\w@?^=%&;/~+#-])?';
    const regex = new RegExp(expression);
    const result = link.match(regex);
    if (result) {
      return true;
    }
    return false;
  }
};

export const globalReplaceAll = (str: string, find: string, replace: string) => {
  if (str && find && replace) {
    return str.split(find).join(replace);
  }
};

export const splitUrl = (url?: string, defaultParam?: object, preventChangeDash = false) => {
  if (url) {
    const spli2 = url.split('&').join();
    const spli3 = spli2.split('?');
    const b = spli3.slice(1, spli3.length);
    const c = b[0].split(',');
    const d = c.map((x) => {
      const splitEqual = x.split('=');
      return { label: splitEqual[0], value: splitEqual[1] };
    });
    let queryValue = {};

    for (let i = 0; i < d.length; i++) {
      if (!preventChangeDash) {
        queryValue = { ...queryValue, [d[i].label]: d[i].value ? globalReplaceAll(d[i].value, '-', ',') : d[i].value };
      } else {
        queryValue = { ...queryValue, [d[i].label]: d[i].value };
      }
    }
    return queryValue;
  } else {
    return defaultParam || {};
  }
};

export const customValidation = (validationCriteria: string[] = [], data: any) => {
  if (data) {
    let emptyState: string[] = [];
    let notEmptyState: string[] = [];
    let emptyStateToObj = {};
    let notEmptyStateObj = {};
    for (let i = 0; i < validationCriteria.length; i++) {
      if (
        !data[validationCriteria[i]] ||
        data[validationCriteria[i]] === '' ||
        data[validationCriteria[i]].length <= 0
      ) {
        emptyState = [...emptyState, validationCriteria[i]];
        emptyStateToObj = { ...emptyStateToObj, [validationCriteria[i]]: 'Wajib di isi' };
      } else {
        notEmptyState = [...notEmptyState, validationCriteria[i]];
        notEmptyStateObj = { ...notEmptyStateObj, [validationCriteria[i]]: 'Wajib di isi' };
      }
    }
    return {
      emptyState,
      notEmptyState,
      emptyStateToObj,
      notEmptyStateObj,
    };
  }
};

export const whatsappPhone = (userData: Admin.MyData): string => {
  if (userData.phone && typeof userData.phone === 'string') {
    return '0' + userData.phone.slice(2, userData.phone.length);
  }
  return '';
};

export const formatMoney = (value?: string) => {
  if (value) {
    return value.replace(/(\d)(?=(\d{3})+$)/g, '$1.');
  }
  return 0;
};

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const generateRandomId = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const b64toBlob = (base64Data: any, contentType?: string) => {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export function buildParams(params: { [key: string]: any }): string {
  if (!params || typeof params !== 'object') {
    return '';
  }

  const paramStrings: string[] = [];

  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      // Exclude empty strings, 0, and empty arrays from the query string
      if (
        value !== undefined &&
        (typeof value !== 'string' || value !== '') &&
        (typeof value !== 'number' || value !== 0) &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        const encodedValue = encodeURIComponent(value);
        paramStrings.push(`${key}=${encodedValue}`);
      }
    }
  }

  return paramStrings.length > 0 ? `?${paramStrings.join('&')}` : '';
}
