import axios, { AxiosRequestConfig } from 'axios';
import { fetcher } from '@/utils';

export const getStatus = async (params?: AxiosRequestConfig): Promise<Order.Status[]> => {
  try {
    const { data: response } = await fetcher.get('/v1/order/status_order/list', params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrders = async (params?: AxiosRequestConfig): Promise<Order.Data> => {
  try {
    const { data: response } = await fetcher.get('/v1/order', {
      params: {
        ...params?.params,
        full: 1,
      },
    });
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
