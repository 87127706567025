import React from 'react';
import { chakra, Flex, Box, Text, Input, useCheckbox, CheckboxProps } from '@chakra-ui/react';
import { BsCheck } from 'react-icons/bs';

interface Props extends CheckboxProps {
  label: string;
}

const Checkbox = (props: Props): JSX.Element => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);

  return (
    <chakra.label
      display="flex"
      background={state.isChecked ? 'white' : '#f4f4f4'}
      border="1px solid"
      borderColor={state.isChecked ? 'primary.default' : '#f4f4f4'}
      paddingX={3}
      paddingY={1}
      cursor="pointer"
      {...htmlProps}
    >
      <Flex justifyContent="space-between" flex={1} {...getCheckboxProps()}>
        <Text fontSize="sm" color="#4a4a4a" {...getLabelProps()}>
          {props.label}
        </Text>
        {state.isChecked && <Box as={BsCheck} color="primary.default" size={21} marginLeft="auto" />}
      </Flex>
      <Input {...getInputProps()} hidden />
    </chakra.label>
  );
};

export default Checkbox;
