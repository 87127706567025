import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface AddressCreateReducerProps {
  data: Address.FormAddressProps | null;
}

const initialState: AddressCreateReducerProps = {
  data: null,
};

const AddressCreateReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.ADDRESS_CREATE:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default AddressCreateReducer;
