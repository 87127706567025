import { fetcher, handleParamUrl } from '@/utils';
import axios from 'axios';

export interface ParamHistoryReferral {
  start_date?: string;
  end_date?: string;
  order_by?: string;
  order_dir?: string;
  page?: string | number;
  limit?: string | number;
}

interface PayloadSubmitMentor {
  code: string;
}

export const getReferralTier = async () => {
  try {
    const url = '/v1/referral/commission_tier';
    const { data: response } = await fetcher.get(url);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getReferralSk = async (mode?: string) => {
  try {
    const url = '/v1/referral/tnc';
    const { data: response } = await fetcher.get(url);
    if (mode === 'pemberi') {
      const filter = response.data.referral_tncs.find(
        (referral: Admin.ReferralTcData) => referral.tnc_type === 'TNCGIVE',
      );
      return filter;
    }
    const filter = response.data.referral_tncs.find(
      (referral: Admin.ReferralTcData) => referral.tnc_type === 'TNCRECIPIENT',
    );
    return filter;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getReferralTransaction = async () => {
  try {
    const url = `/v1/payment/withdraw/history`;
    const { data: response } = await fetcher.get(url);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getReferralSummary = async (period: string) => {
  const url = `/v1/referral/summary?periode=${period}`;
  try {
    const { data: response } = await fetcher.get(url);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getReferralHistory = async (payload: ParamHistoryReferral) => {
  const url = '/v1/referral/history';
  const paramUrl = handleParamUrl(url, payload);
  try {
    const { data: response } = await fetcher.get(paramUrl);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const checkReferralCode = async (code: string) => {
  try {
    const url = `/v1/referral/code/${code}`;
    const { data: response } = await fetcher.get(url);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const submitMentor = async (payload: PayloadSubmitMentor) => {
  try {
    const url = '/v1/referral';
    const { data: response } = await fetcher.post(url, payload);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
