import axios from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const getOrderFinance = async (status: number) => {
  try {
    const url = API.ORDER_FINANCE(status, 1);
    const { data: response } = await fetcher.get(url);
    const data: Order.OrderFinanceResponse[] = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getWithdrawHistory = async () => {
  try {
    const url = API.ORDER_FINANCE_HISTORY;
    const { data: response } = await fetcher.get(url);
    const data: Order.OrderFinanceHistory[] = response?.data?.resellerPaymentHistories;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getRewardHistory = async () => {
  try {
    const url = API.REWARD_HISTORY;
    const { data: response } = await fetcher.get(url);
    const data: Order.OrderRewardHistory[] = response?.data?.history;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getRewardDetail = async (rewardID: number) => {
  try {
    const url = API.REWARD_DETAIL(rewardID);
    const { data: response } = await fetcher.get(url);
    const data: Order.OrderRewardDetail = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getBankList = async () => {
  try {
    const url = API.BANK_LIST;
    const { data: response } = await fetcher.get(url);
    const data: Order.BankList[] = response?.data?.banks;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getRekeningList = async () => {
  try {
    const url = API.REKENING_LIST;
    const { data: response } = await fetcher.get(url);
    const data: Order.RekeningList[] = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postRekeningCreate = async (request: Order.RekeningCreateRequest) => {
  try {
    const url = API.REKENING_ADD;
    const { data: response } = await fetcher.post(url, request);
    const data: Order.RekeningList = response?.data;
    return { data: data, message_client: response?.message_client };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postWithdraw = async (request: Order.WithdrawRequest) => {
  try {
    const url = API.WITHDRAW;
    const { data: response } = await fetcher.post(url, request);
    return {
      success: response?.success || false,
      message_client: response?.message_client || '',
    };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postClaimReward = async (request: Order.ClaimRewardRequest) => {
  try {
    const url = API.REWARD_CLAIM;
    // const formData = new FormData();
    // formData.append('journey_id', request.journey_id.toString());
    const { data: response } = await fetcher.post(url, request);
    return {
      success: response?.success || false,
      message_client: response?.message_client || '',
    };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getHoldSaldo = async () => {
  try {
    const url = API.HOLD_SALDO;
    const { data: response } = await fetcher.get(url);
    const data: Order.HoldSaldoAmount = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getHoldSaldoList = async (request: { statusName: string; page: number }) => {
  try {
    const url = API.HOLD_SALDO_LIST(request.statusName, request.page);
    const { data: response } = await fetcher.get(url);
    const data: Order.HoldSaldoItem[] = response?.data?.result;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
