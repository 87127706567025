const STORAGE = {
  USER_DATA: 'UserData',
  MY_TOKO: 'MyToko',
  SHARE_PRODUCT: 'ShareProduct',
  REFERRAL: 'Referral',
  CATEGORY: 'Category',
  CATEGORY_DETAIL: 'CategoryDetail',
  SUPPLIER_CATEGORY_DETAIL: 'SupplierCategoryDetail',
  SUPPLIER: 'Supplier',
  PRODUCT_DETAIL: 'ProductDetail',
  SEARCH_PRODUCT: 'SearchProduct',
  SEARCH_SORT: 'SearchSort',
  SEARCH_UTIL: 'SearchUtil',
  SEARCH_FILTER: 'SearchFilter',
  ADDRESS_INTRO: 'AddressIntro',
  ADDRESS_EDIT: 'AddressEdit',
  ADDRESS_CREATE: 'AddressCreate',
  PRE_CHECKOUT_INIT: 'PreCheckoutInit',
  PRE_CHECKOUT_REQUEST: 'PreCheckoutRequest',
  PRE_CHECKOUT: 'PreCheckout',
  FORM_KYC: 'FormKYC',
  FORM_PROFILE: 'FormProfile',
  PENALTY: 'Penalty',
  SELECTED_CART: 'SelectedCart',
  ADDRESS_CHECKOUT: 'AddressCheckout',
  PRE_CHECKOUT_NEW: 'PreCheckoutNew',
};

export default STORAGE;
