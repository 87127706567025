import axios from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const getProductDetail = async (productID: number, type: string) => {
  try {
    const url = API.PRODUCT_DETAIL(productID, type);
    const { data: response } = await fetcher.get(url);
    let data;
    if (type === 'bundle') {
      data = response?.data;
    } else {
      data = response?.data?.product;
    }
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getProductDetailBundle = async (productID: number) => {
  try {
    const url = API.PRODUCT_DETAIL_BUNDLE(productID);
    const { data: response } = await fetcher.get(url);
    const data: Product.ProductDetaiProps = response?.data?.product;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
