import axios, { AxiosRequestConfig } from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const getFilterSort = async (params?: AxiosRequestConfig): Promise<Product.FilterSort[]> => {
  try {
    const { data: response } = await fetcher.get(API.FILTER_SORT, params);
    const data: Product.FilterSort[] = response?.data?.sortList;
    return data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getFilterUtil = async (params?: AxiosRequestConfig): Promise<Product.Filter[]> => {
  try {
    const { data: response } = await fetcher.get(API.FILTER_UTIL, params);
    const data: Product.Filter[] = response?.data?.product_filters;
    return data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
