import { useEffect } from 'react';
// import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const GoogleMapsLoader = () => {
  useEffect(() => {
    const loadGoogleScript = () => {
      if (typeof window.google === 'undefined') {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        script.addEventListener('load', handleScriptLoad);
      } else {
        handleScriptLoad();
      }
    };

    loadGoogleScript();

    return () => {
      // Clean up function to remove the script when component unmounts
      const googleScript = document.querySelector('script[src^="https://maps.googleapis.com"]');
      if (googleScript) {
        googleScript.remove();
      }
    };
  }, []);

  const handleScriptLoad = () => {
    // You should not call hooks here
    console.log('Google script loaded');
  };

  // Call usePlacesService within the component body
  // usePlacesService({
  //   apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  //   language: 'id',
  //   libraries: ['places'],
  //   options: {
  //     input: '',
  //     types: [],
  //     componentRestrictions: { country: 'id' },
  //   },
  // });

  return null;
};

export default GoogleMapsLoader;
