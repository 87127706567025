import axios, { AxiosRequestConfig } from 'axios';
import { fetcher } from '@/utils';

export const getBanner = async (params?: AxiosRequestConfig): Promise<{ banners: Home.BannerItem[] }> => {
  try {
    const { data: response } = await fetcher.get('/v1/marketing/banner/list', params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getProductFilterType = async (params?: AxiosRequestConfig): Promise<Home.ProductFilterItem> => {
  try {
    const { data: response } = await fetcher.get('/v1/product/util/type', params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getProductFilter = async (params?: AxiosRequestConfig): Promise<Home.ProductFilter> => {
  try {
    const { data: response } = await fetcher.get<{ data: Home.ProductFilter }>('/v1/product/util/filter', params);

    const newResponse = response.data.product_filters.map((item) => {
      return {
        ...item,
        data: item.data.map((item) => {
          if (item.id === 'jabodetabek') {
            return {
              ...item,
              id: 0,
              checked: false,
            };
          }
          return {
            ...item,
            checked: false,
          };
        }),
      };
    }) as [{ text: string; data: [{ id: string | number; name: string; checked: boolean }] }];

    return { product_filters: newResponse };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getProductsNewest = async (params?: AxiosRequestConfig): Promise<Home.ProductResponse> => {
  try {
    const { data: response } = await fetcher.get('/v1/product/newest', params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getProductsRecomend = async (params?: AxiosRequestConfig): Promise<Home.ProductResponse> => {
  try {
    const { data: response } = await fetcher.get('/v1/product/recommended', params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getProductsBestSeller = async (params?: AxiosRequestConfig): Promise<Home.ProductResponse> => {
  try {
    const { data: response } = await fetcher.get('v1/product/best_seller', params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getProductsTranding = async (params?: AxiosRequestConfig): Promise<Home.ProductResponse> => {
  try {
    const { data: response } = await fetcher.get('v1/product/trending', params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
