import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface AddressCheckoutReducerProps {
  data: any | null;
}

const initialState: AddressCheckoutReducerProps = {
  data: null,
};

const AddressCheckoutReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.ADDRESS_CHECKOUT:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }

  return state;
};

export default AddressCheckoutReducer;
