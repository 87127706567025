import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import rootReducer from './reducers';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import STORAGE from '@/constant/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'UserData',
    'MyToko',
    'ShareProduct',
    'Referral',
    STORAGE.CATEGORY,
    STORAGE.CATEGORY_DETAIL,
    STORAGE.SEARCH_PRODUCT,
    STORAGE.SEARCH_SORT,
    STORAGE.SEARCH_UTIL,
    STORAGE.PRODUCT_DETAIL,
    STORAGE.SUPPLIER_CATEGORY_DETAIL,
    STORAGE.SUPPLIER,
    STORAGE.ADDRESS_CREATE,
    STORAGE.ADDRESS_EDIT,
    STORAGE.ADDRESS_INTRO,
    STORAGE.PENALTY,
    STORAGE.PRE_CHECKOUT_INIT,
    STORAGE.PRE_CHECKOUT_REQUEST,
    STORAGE.PRE_CHECKOUT,
    STORAGE.SELECTED_CART,
    STORAGE.ADDRESS_CHECKOUT,
    STORAGE.PRE_CHECKOUT_NEW,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
