import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface AddressIntroReducerProps {
  data: Address.FormAddressProps | null;
}

const initialState: AddressIntroReducerProps = {
  data: null,
};

const AddressIntroReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.ADDRESS_INTRO:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default AddressIntroReducer;
