import React from 'react';
import Image from 'next/image';
import checkbox1 from '@/assets/images/checkbox.svg';
import checkbox2 from '@/assets/images/checkbox-active.svg';
const OptionCheckBox: React.FC<Options.ICheckOptions> = ({ initialValue, onChangeValue }): JSX.Element => {
  return initialValue ? (
    <Image
      onClick={() => {
        if (onChangeValue) onChangeValue(false);
      }}
      src={checkbox2}
      alt="checkbox1"
    />
  ) : (
    <Image
      onClick={() => {
        if (onChangeValue) onChangeValue(true);
      }}
      src={checkbox1}
      alt="checkbox2"
    />
  );
};

export default OptionCheckBox;
