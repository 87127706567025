import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface SearchSortReducerProps {
  data: Product.FilterSort[] | null;
}

const initialState: SearchSortReducerProps = {
  data: null,
};

const SearchSortReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.SEARCH_SORT:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default SearchSortReducer;
