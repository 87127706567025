import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface PreCheckoutRequestReducerProps {
  data: Order.PreCheckoutRequestProps | null;
}

const initialState: PreCheckoutRequestReducerProps = {
  data: null,
};

const PreCheckoutRequestReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.PRE_CHECKOUT_REQUEST:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default PreCheckoutRequestReducer;
