/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */

import { getProductDetail, getProductDetailBundle } from '@/lib/api/product';
import { saveDetailProdcut, saveProductDescription, saveShareImage } from '@/store/share-product/action';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useState } from 'react';
import { useAppDispatch } from '../../utils/useRedux';
import useCart from '../order/cart';
import useEventLog from '@/lib/hooks/utils/useEventLog';
import { useAppSelector } from '@/store';
import { saveProductDetail } from '@/store/product_detail/action';

// Product Detail Data
const useProductDetail = () => {
  const onGetProductDetail = ({
    params,
    type,
    option,
  }: {
    params: number;
    type: string;
    option?: UseQueryOptions<unknown, unknown, Product.ProductDetaiProps, any>;
  }) => {
    return useQuery(['productDetail'], () => getProductDetail(params, type), option);
  };
  const onGetProductDetailBundle = ({
    params,
    option,
  }: {
    params: number;
    option?: UseQueryOptions<unknown, unknown, Product.ProductDetaiProps, any>;
  }) => {
    return useQuery(['productDetailBundle'], () => getProductDetailBundle(params), option);
  };
  const dispatch = useAppDispatch();
  const { LOGS, writeLog } = useEventLog();
  const productDetailData = useAppSelector((state) => state.ProductDetail.data);
  const [productImages, setProductImages] = useState<Product.ProductImageProps[]>([]);
  const [productInfo, setProductInfo] = useState<Product.ProductInfoProps>();
  const [supplierInfo, setSupplierInfo] = useState<Supplier.SupplierInfoProps>();
  const [options, setOptions] = useState<Product.VariantProps[]>([]);
  const [selectedVarian, setSelectedVariant] = useState<number[]>([]);
  const [selectedSKU, setSelectedSKU] = useState<Product.SKUProps>();
  const [supplierData, setSupplierData] = useState<Supplier.SupplierDetailProps>();
  const [productData, setProductData] = useState<any>();
  const [markupMode, setMarkupMode] = useState<number>(0); // 0 = %, 1 = Rp
  const [resellerPrice, setResellerPrice] = useState<number>(0);
  const [reloadProductDetail, setReloadProductDetail] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [stockCombination, setStockCombination] = useState<Product.StockCombination[]>([]);

  const mappingImageShare = (data: any) => {
    let imageShare;
    if (data.images) {
      imageShare = data.images?.map((image) => ({ url: image.url, isSelected: false }));
    } else {
      imageShare = data.product_bundling.map((image) => ({ url: image.image.url, isSelected: false }));
    }
    dispatch(saveShareImage(imageShare));
  };

  const setProductDetailData = (data: any) => {
    dispatch(saveProductDescription(data.description));
    dispatch(saveDetailProdcut(data));
    dispatch(saveProductDetail(data));

    mappingImageShare(data);
    setProductData(data);
    if (data.images) {
      setProductImages(data.images);
    } else {
      setProductImages(data.product_bundling.map((p) => ({ url: p.image.url })));
    }
    setProductInfo({
      id: data.id,
      description: data.description,
      name: data.name,
      notes: data.notes,
      status: data.status,
      weight: data.weight,
      sold_alias: data.sold_alias,
    });
    setSupplierInfo(data.supplier);
    let optionTemp: Product.VariantProps[] = [];
    const variantTemp: number[] = [];
    const tempStockCombination: Product.StockCombination[] = [];
    let variantName = '';

    data?.option_types?.forEach((it, i) => {
      const optionValueTemp: Product.OptionValueProps[] = [];
      data.option_values.forEach((it2) => {
        if (it2.opt_type_id === it.id) optionValueTemp.push(it2);
      });
      if (optionValueTemp.length > 0) {
        if (data?.option_types?.length === 1) {
          variantName += optionValueTemp[0].name;
        } else {
          if (i === data.option_types.length - 1) variantName += optionValueTemp[0].name;
          else variantName += `${optionValueTemp[0].name}-`;
        }
        optionTemp.push({
          type: it,
          values: optionValueTemp,
          valuesTag: optionValueTemp.map((it2, i2) => {
            return { is_active: i2 === 0, text: it2.name, stock: 0 };
          }),
        });
        variantTemp.push(0);
      }
    });

    if (optionTemp.length > 0) {
      const tempSKUs: any = {};
      data.product_sku.forEach((it) => {
        tempSKUs[it.variant_name] = it;
        const optionTemp2: Product.VariantProps[] = [];
        optionTemp.forEach((it2, i2) => {
          const temp = { ...it2 };
          if (i2 === 0) {
            it2.valuesTag.forEach((it3, i3) => {
              if (it.variant_name.includes(`${it3.text}${optionTemp.length > 1 ? '-' : ''}`)) {
                temp.valuesTag[i3] = { ...it3, stock: it3.stock + it.stock };
              }
            });
          } else if (i2 < optionTemp.length - 1) {
            it2.valuesTag.forEach((it3, i3) => {
              if (it.variant_name.includes(`-${it3.text}-`)) {
                temp.valuesTag[i3] = { ...it3, stock: it3.stock + it.stock };
              }
            });
          } else if (i2 === optionTemp.length - 1) {
            it2.valuesTag.forEach((it3, i3) => {
              if (it.variant_name.includes(`-${it3.text}`)) {
                temp.valuesTag[i3] = { ...it3, stock: it3.stock + it.stock };
              }
            });
          }
          optionTemp2.push(temp);
        });
        optionTemp = optionTemp2;
      });

      setStockCombination(tempStockCombination);
      setOptions(optionTemp);
      setSelectedVariant(variantTemp);
      const tempSKU = data.product_sku?.find((it2) => variantName.includes(it2.variant_name));
      if (tempSKU !== undefined) setSelectedSKU(tempSKU);
    } else setSelectedSKU(data.product_sku?.[0]);
  };

  const updateSupplierData = (data: Supplier.SupplierDetailProps) => {
    // Setup Analytics Supplier Detail
    setSupplierData(data);
  };

  const updateVariant = (optionIndex: number, variantIndex: number) => {
    const temp = [...selectedVarian];
    let variantName = '';
    temp[optionIndex] = variantIndex;
    setSelectedVariant(temp);
    temp.forEach((it, iOption) => {
      if (iOption === temp.length - 1) variantName += options[iOption]?.values[it].name;
      else variantName += `${options[iOption]?.values[it].name}-`;
    });
    const tempSKU = productData?.product_sku?.find((it2) => it2.variant_name === variantName);
    if (tempSKU !== undefined) {
      setSelectedSKU(tempSKU);
      const tempOptions = [...options];
      tempOptions[optionIndex].valuesTag = tempOptions[optionIndex].valuesTag.map((it, iTag) => {
        return {
          ...it,
          text: it.text,
          is_active: iTag === variantIndex,
        };
      });
      setOptions(tempOptions);

      // Setup Analytics Supplier Detail
      writeLog(
        optionIndex === 0
          ? LOGS.CLICK.CLICK_PRODUCT_DETAIL_VARIANT_1
          : optionIndex === 1
          ? LOGS.CLICK.CLICK_PRODUCT_DETAIL_VARIANT_2
          : optionIndex === 2
          ? LOGS.CLICK.CLICK_PRODUCT_DETAIL_VARIANT_3
          : LOGS.CLICK.CLICK_PRODUCT_DETAIL_VARIANT_4,
        tempSKU,
      );
    }
  };

  const changeMarkupMode = (mode: number, selectedSKU: Product.SKUProps | undefined) => {
    setMarkupMode(mode);
    if (selectedSKU !== undefined) {
      setResellerPrice(selectedSKU?.marketplace_price || 0);

      // Setup Analytics Change Markup Mode
      writeLog(
        mode === 0
          ? LOGS.CLICK.CLICK_PRODUCT_DETAIL_MARKUP_TYPE_PERCENTAGE
          : LOGS.CLICK.CLICK_PRODUCT_DETAIL_MARKUP_TYPE_RUPIAH,
      );
    }
  };

  const updateResellerPrice = (markup: number, selectedSKU: Product.SKUProps | undefined) => {
    if (selectedSKU !== undefined) {
      const priceAfterMarkup =
        selectedSKU?.marketplace_price +
        (markupMode === 0 ? (selectedSKU?.marketplace_price * markup) / 100.0 : markup);
      setResellerPrice(priceAfterMarkup);
    }
  };

  const updateReloadProductDetail = (status: boolean) => {
    setReloadProductDetail(status);
  };

  const checkIsProductIDValid = (productID: string) => {
    if (productDetailData !== null) {
      if (productDetailData.id.toString() === productID) {
        updateReloadProductDetail(false);
        setProductDetailData(productDetailData);
      } else updateReloadProductDetail(true);
    } else updateReloadProductDetail(true);
  };

  const toggleShare = () => setOpenShare((prevState) => !prevState);

  return {
    stockCombination,
    productImages,
    productInfo,
    options,
    selectedSKU,
    supplierInfo,
    supplierData,
    markupMode,
    resellerPrice,
    productData,
    reloadProductDetail,
    setProductDetailData,
    updateSupplierData,
    updateVariant,
    changeMarkupMode,
    updateResellerPrice,
    updateReloadProductDetail,
    checkIsProductIDValid,
    // API
    onGetProductDetail,
    onGetProductDetailBundle,
    openShare,
    toggleShare,
  };
};

export { useCart, useProductDetail };
