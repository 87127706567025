import React from 'react';
import {
  PinInput as ChakraPinInput,
  PinInputField as ChakraPinInputField,
  PinInputProps as ChakraPinInputProps,
  HStack,
} from '@chakra-ui/react';

type PinInputProps = Omit<ChakraPinInputProps, 'children'> & {
  numInputs?: number;
};

const PinInput: React.FC<PinInputProps> = ({
  placeholder = '',
  type = 'number',
  numInputs = 4,
  ...rest
}): JSX.Element => {
  return (
    <HStack>
      <ChakraPinInput
        type={type}
        variant="flushed"
        autoFocus
        {...rest}
        focusBorderColor="primary.default"
        placeholder={placeholder}
      >
        {[...Array(numInputs)].map((_, index) => (
          <ChakraPinInputField key={index} fontWeight="bold" borderBottomWidth="medium" />
        ))}
      </ChakraPinInput>
    </HStack>
  );
};

export default PinInput;
