import React from 'react';

function Icon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.467 3.775a.686.686 0 00-.058.035c-.081.052-.186.13-.373.27L5.168 9.212c-.5.374-.61.465-.683.56a1.03 1.03 0 00-.173.34c-.031.108-.04.238-.04.848V18c0 .56.001.925.025 1.203.022.267.061.374.093.433.104.197.274.364.492.47.074.036.198.076.484.098.295.023.68.024 1.26.024h11.747c.581 0 .966 0 1.261-.024.286-.022.41-.062.484-.098.218-.106.388-.273.492-.47.032-.06.07-.166.093-.433.024-.278.025-.643.025-1.203v-7.04c0-.61-.009-.74-.04-.847a1.028 1.028 0 00-.173-.34c-.074-.096-.183-.187-.683-.56L12.964 4.08a7.427 7.427 0 00-.373-.27.686.686 0 00-.058-.035.167.167 0 00-.066 0zm-.455-1.713c.32-.083.656-.083.976 0 .372.097.688.335.964.543l.073.055 6.868 5.133.076.056c.385.287.708.529.95.843.213.276.373.59.47.925.112.382.111.784.11 1.25v7.169c0 .514 0 .955-.03 1.317-.032.383-.104.756-.293 1.113a2.873 2.873 0 01-1.278 1.232c-.36.175-.735.242-1.125.273-.373.029-.828.029-1.366.029H6.593c-.539 0-.993 0-1.366-.03-.39-.03-.765-.096-1.125-.272a2.874 2.874 0 01-1.278-1.232c-.19-.357-.26-.73-.293-1.113-.03-.362-.03-.803-.03-1.317V10.96v-.093c-.001-.467-.002-.868.11-1.25.097-.335.257-.649.47-.925.242-.314.565-.556.95-.843l.076-.056 6.868-5.133.073-.055c.276-.208.592-.446.964-.543zM8.348 13.447a.886.886 0 011.085.627c.343 1.28 1.569 2.262 3.067 2.262 1.498 0 2.724-.982 3.067-2.262a.886.886 0 111.712.458c-.557 2.077-2.502 3.576-4.779 3.576s-4.222-1.499-4.779-3.576a.886.886 0 01.627-1.085z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
