import { TRANSFER_BANK, COD, PAID, UNPAID, QRIS } from '@/constant';

export const WordMapping = (Text: string): string => {
  switch (Text) {
    case TRANSFER_BANK:
      return 'Transfer Bank';
    case QRIS:
      return 'QRIS';
    case COD:
      return 'COD (Cash On Delivery)';
    case PAID:
      return 'Lunas';
    case UNPAID:
      return 'Belum Lunas';

    default:
      return 'Belum Lunas';
  }
};

export const BackgroundColorMapping = (Text: string): string => {
  switch (Text) {
    case COD:
      return '#c6caee';
    case PAID:
      return '#c3ffd9';
    case UNPAID:
      return '#ffc2c2';

    default:
      return '#ffc2c2';
  }
};

export const TextColorMapping = (Text: string): string => {
  switch (Text) {
    case COD:
      return '#6b78ee';
    case PAID:
      return '#2b814a';
    case UNPAID:
      return '#d0021b';

    default:
      return '#d0021b';
  }
};
