import axios from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const postPreCheckout = async (request: Order.PreCheckoutRequestProps) => {
  try {
    const url = API.PRE_CHECKOUT;
    const { data: response } = await fetcher.post(url, request);
    const data: Order.PreCheckoutResponseProps = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postCheckout = async (request: Order.PreCheckoutRequestProps) => {
  try {
    const url = API.CHECKOUT;
    const { data: response } = await fetcher.post(url, request);
    const data: Order.CheckoutResponseProps = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const shippingCost = async (request: Order.ShippingCostRequestProps) => {
  try {
    const url = API.SHIPPING_ESTIMATION;
    const { data: response } = await fetcher.post(url, request);
    const data = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
