import React from 'react';

function Icon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.389 3.79a10.99 10.99 0 00-.879-.018H9.656c-.762 0-1.279 0-1.677.034-.388.033-.585.091-.721.163a1.814 1.814 0 00-.782.803c-.074.15-.133.36-.165.763-.033.413-.033.946-.033 1.726v9.478c0 .78 0 1.313.033 1.726.032.403.091.614.165.763.174.35.449.63.782.803.136.072.333.13.72.163.4.033.916.034 1.678.034h5.688c.762 0 1.279 0 1.677-.034.388-.033.585-.091.721-.163.333-.174.608-.453.782-.803.074-.15.133-.36.165-.763.032-.413.033-.946.033-1.726v-6.573c0-.498-.003-.752-.02-.925h-3.03c-.225 0-.444 0-.63-.016a1.78 1.78 0 01-.691-.185 1.787 1.787 0 01-.773-.79 1.835 1.835 0 01-.174-.685 8.484 8.484 0 01-.015-.64V3.79zm1.248-1.47a3.512 3.512 0 00-.628-.219C13.595 2 13.165 2 12.602 2H9.618c-.715 0-1.306 0-1.788.04-.502.042-.964.133-1.398.36-.67.35-1.212.907-1.549 1.586-.217.436-.303.9-.344 1.409C4.5 5.886 4.5 6.49 4.5 7.225v9.55c0 .735 0 1.339.04 1.83.04.509.126.973.343 1.41a3.589 3.589 0 001.55 1.586c.433.226.895.317 1.397.359.482.04 1.073.04 1.788.04h5.764c.715 0 1.306 0 1.788-.04.502-.042.964-.133 1.398-.36a3.589 3.589 0 001.549-1.586c.217-.436.303-.9.344-1.409.039-.491.039-1.095.039-1.83v-6.608-.09c0-.582 0-1.014-.097-1.43a3.66 3.66 0 00-.421-1.042c-.22-.367-.52-.675-.92-1.085l-.063-.064-2.834-2.906-.064-.066c-.4-.41-.7-.718-1.06-.945a3.537 3.537 0 00-.404-.218zm.53 2.749v1.827a7.642 7.642 0 00.012.562l.012.001c.102.009.245.01.509.01h1.807l-2.34-2.4zM8.056 12.91c0-.489.398-.886.888-.886h7.111c.491 0 .89.397.89.886 0 .49-.399.886-.89.886h-7.11a.887.887 0 01-.89-.886zm0 3.646c0-.49.398-.886.888-.886h5.334c.49 0 .889.397.889.886 0 .49-.398.886-.89.886H8.945a.888.888 0 01-.888-.886z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
