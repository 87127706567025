import { deleteProductFromFavorite, postProductToFavorite } from '@/lib/api/product';
import { useMutation } from '@tanstack/react-query';

const useFavorite = () => {
  // API
  const {
    mutate: mutationProductToFavorite,
    isError: isErrorProductToFavorite,
    reset: resetStateProductToFavorite,
  } = useMutation(postProductToFavorite);
  const {
    mutate: mutationProductFromFavorite,
    isError: isErrorProductFromFavorite,
    reset: resetStateFromFavorite,
  } = useMutation(deleteProductFromFavorite);

  const onPostProductToFavorite = (request: Product.AddToFavoriteRequestProps, callback: (message: string) => void) => {
    mutationProductToFavorite(request, {
      onSuccess: (response) => {
        if (response !== undefined) callback(response.message_client);
      },
      onError: () => {
        resetStateProductToFavorite();
      },
    });
  };

  const onDeleteProductFromFavorite = (productID: number, callback: (message: string) => void) => {
    mutationProductFromFavorite(productID, {
      onSuccess: (response) => {
        if (response !== undefined) callback(response.message_client);
      },
      onError: () => {
        resetStateFromFavorite();
      },
    });
  };

  return {
    onPostProductToFavorite,
    isErrorProductToFavorite,
    onDeleteProductFromFavorite,
    isErrorProductFromFavorite,
  };
};

export default useFavorite;
