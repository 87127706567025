import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';

const AlertDialogComponent: React.FC<AlertDialog.AlertDialogProps> = React.forwardRef((props, ref: any) => {
  return (
    <AlertDialog leastDestructiveRef={ref} isCentered={true} isOpen={props.isOpen} onClose={props.onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent marginX={4}>
          <AlertDialogBody>{props.message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button marginRight={2} ref={ref} onClick={props.onClose}>
              No
            </Button>
            <Button isLoading={props.loadingSubmit} disabled={props.loadingSubmit} onClick={props.onSubmit}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});

AlertDialogComponent.displayName = 'alert-dialog-component';

export default AlertDialogComponent;
