import axios from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const getCategories = async () => {
  try {
    const url = API.COMPLAINT_CATEGORIES;
    const { data: response } = await fetcher.post(url, {
      id: '',
    });
    const data: Order.CategoryComplaint[] = response?.data?.result || [];
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postClaim = async (request: Order.FormComplaint) => {
  try {
    const url = API.COMPLAINT_NEW;
    const { data: response } = await fetcher.post(url, request);
    const data: string = response?.data?.id || '';
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getExpectations = async (categories: string) => {
  try {
    const url = API.COMPLAINT_EXPECTATIONS;
    const { data: response } = await fetcher.post(url, {
      categories: categories,
    });
    const data: Order.ExpectationComplaint[] = response?.data?.result || [];
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postAttachment = async (request: FormData) => {
  try {
    const url = API.COMPLAINT_ATTACHMENT;
    const { data: response } = await fetcher.post(url, request);
    const data: Order.ComplaintAttachment = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getDetailComplaint = async (complaintID: string) => {
  try {
    const url = API.COMPLAINT_DETAIL(complaintID);
    const { data: response } = await fetcher.get(url);
    const data: Order.ComplaintDetail = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
