/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */

import { AxiosRequestConfig } from 'axios';
import {
  getBanner,
  getProductFilterType,
  getProductFilter,
  getProductsNewest,
  getProductsBestSeller,
  getProductsRecomend,
  getProductsTranding,
} from '@/api/home';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

export const useHome = () => {
  const onGetBanner = (params?: AxiosRequestConfig) => {
    return useQuery(['banner', params], () => getBanner(params), { cacheTime: 60 * 1000 * 10, staleTime: 60 * 1000 });
  };

  const onGetProductFilterType = (params?: AxiosRequestConfig) => {
    return useQuery(['productFilterType', params], () => getProductFilterType(params));
  };

  const onGetProductFilter = ({
    params,
    option,
  }: {
    params?: AxiosRequestConfig;
    option?: UseQueryOptions<unknown, unknown, Home.ProductFilter, any>;
  }) => {
    return useQuery(['productFilter', params], () => getProductFilter(params), option);
  };

  const onGetProductsNewest = ({
    config,
    option,
  }: {
    config?: AxiosRequestConfig;
    option?: UseQueryOptions<unknown, unknown, Home.ProductResponse, any>;
  }) => {
    return useQuery(['productsNewest', config], () => getProductsNewest(config), {
      ...option,
      // cacheTime: 60 * 1000 * 10,
      // staleTime: 60 * 1000,
    });
  };

  const onGetProductsBestSeller = ({
    config,
    option,
  }: {
    config?: AxiosRequestConfig;
    option?: UseQueryOptions<unknown, unknown, Home.ProductResponse, any>;
  }) => {
    return useQuery(['productsBestSeller', config], () => getProductsBestSeller(config), {
      ...option,
      // cacheTime: 60 * 1000 * 10,
      // staleTime: 60 * 1000,
    });
  };

  const onGetProductsRecomend = ({
    config,
    option,
  }: {
    config?: AxiosRequestConfig;
    option?: UseQueryOptions<unknown, unknown, Home.ProductResponse, any>;
  }) => {
    return useQuery(['productsRecomend', config], () => getProductsRecomend(config), {
      ...option,
      // cacheTime: 60 * 1000 * 10,
      // staleTime: 60 * 1000,
    });
  };

  const onGetProductsTranding = ({
    config,
    option,
  }: {
    config?: AxiosRequestConfig;
    option?: UseQueryOptions<unknown, unknown, Home.ProductResponse, any>;
  }) => {
    return useQuery(['productsTranding', config], () => getProductsTranding(config), {
      ...option,
      // cacheTime: 60 * 1000 * 10,
      // staleTime: 60 * 1000,
    });
  };

  return {
    onGetBanner,
    onGetProductFilterType,
    onGetProductFilter,
    onGetProductsNewest,
    onGetProductsBestSeller,
    onGetProductsRecomend,
    onGetProductsTranding,
  };
};
