import React from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerProps,
  Text,
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';

interface BottomSheetProps extends DrawerProps {
  children: React.ReactNode;
  withCloseButton?: boolean;
}

interface BottomSheetParentProps {
  Title: typeof BottomSheetTitle;
  Body: typeof BottomSheetBody;
}

interface BottomSheetTitleProps extends BoxProps {
  children: React.ReactNode;
  withBackButton?: boolean;
  onBack?: () => void;
}

interface BottomSheetBodyProps extends BoxProps {
  children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> & BottomSheetParentProps = ({ children, ...rest }): JSX.Element => {
  return (
    <Drawer placement="bottom" {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        {!rest.withCloseButton ? null : <DrawerCloseButton {...rest} />}

        {children}
      </DrawerContent>
    </Drawer>
  );
};

const BottomSheetTitle: React.FC<BottomSheetTitleProps> = ({ children, withBackButton, onBack, ...rest }) => {
  return (
    <>
      <DrawerHeader {...rest}>
        <Box display="flex">
          {withBackButton && (
            <Box marginRight={1.5} onClick={onBack}>
              <FiArrowLeft size={24} style={{ cursor: 'pointer' }} />
            </Box>
          )}
          <Text as="h2" fontSize="md">
            {children}
          </Text>
        </Box>
      </DrawerHeader>
    </>
  );
};

const BottomSheetBody: React.FC<BottomSheetBodyProps> = ({ children, ...rest }) => {
  return <DrawerBody {...rest}>{children}</DrawerBody>;
};

BottomSheet.Title = BottomSheetTitle;
BottomSheet.Body = BottomSheetBody;

BottomSheet.displayName = 'BottomSheet';

export default BottomSheet;
