import { useToast } from '@chakra-ui/toast';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import React from 'react';

const useApp = () => {
  const toast = useToast({
    containerStyle: {
      fontSize: 'xs',
      width: 'xs',
      maxWidth: 'md',
    },
    duration: 5000,
    isClosable: true,
  });
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            // refetchOnMount: false,
          },
          mutations: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess(data: any) {
              if (!toast.isActive('success')) {
                if (data.enableToast) {
                  toast({
                    id: 'success',
                    description: data.message_client,
                    status: 'success',
                    duration: 1500,
                  });
                }
              }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
              if (!toast.isActive('error')) {
                toast({
                  id: 'error',
                  description: error.message_client || 'Terjadi kesalahan, silahkan coba lagi nanti.',
                  status: 'error',
                });
              }
            },
          },
        },
      }),
  );

  const [queryCache] = React.useState(() => new QueryCache());

  return {
    queryClient,
    queryCache,
  };
};

export default useApp;
