import { getFilterSort, getFilterUtil } from '@/lib/api/search-filter';
import { useAppDispatch, useAppSelector } from '@/store';
import { removeSearchProduct, saveSearchProduct } from '@/store/search_product/action';
import { saveSearchSort } from '@/store/search_sort/action';
import { saveSearchUtil } from '@/store/search_util/action';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

const useSearchFilter = () => {
  const {
    mutate: mutateFilterSort,
    isLoading: isLoadingFilterSort,
    reset: resetStateFilterSort,
  } = useMutation(getFilterSort);
  const {
    mutate: mutateFilterUtil,
    isLoading: isLoadingFilterUtil,
    reset: resetStateFilterUtil,
  } = useMutation(getFilterUtil);

  const onGetFilterSort = () => {
    mutateFilterSort(undefined, {
      onSuccess: (res) => {
        if (res !== undefined) dispatch(saveSearchSort(res));
      },
      onError: () => {
        resetStateFilterSort();
      },
    });
  };

  const onGetFilterUtil = () => {
    mutateFilterUtil(undefined, {
      onSuccess: (res) => {
        if (res !== undefined) {
          const temp = searchData || '';
          let jabodetabek = false;
          let tempLocation: string[] = [];
          let tempCategory: string[] = [];
          let tempPayment = false;
          let tempCourier: string[] = [];
          let tempCount = 0;
          if (temp.length > 0) {
            const temp2 = temp.split('&');
            const provinceID = temp2.find((it) => it.includes('province_id'));
            const category1 = temp2.find((it) => it.includes('category1'));
            const courier = temp2.find((it) => it.includes('courier'));
            const isCod = temp2.find((it) => it.includes('is_cod'));
            const isJabodetabek = temp2.find((it) => it.includes('jabodetabek'));
            const lowestPrice = temp2.find((it) => it.includes('lowest_price'));
            const highestPrice = temp2.find((it) => it.includes('highest_price'));
            if (isCod !== undefined) {
              tempPayment = true;
              tempCount += 1;
            }
            if (isJabodetabek !== undefined) {
              jabodetabek = true;
              tempCount += 1;
            }
            if (provinceID !== undefined) {
              const temp = provinceID.split('=')?.[1] || '';
              if (temp.length > 0) {
                tempLocation = temp.split(',');
                tempCount += tempLocation.length;
              }
            }
            if (category1 !== undefined) {
              const temp = category1.split('=')?.[1] || '';
              if (temp.length > 0) {
                tempCategory = temp.split(',');
                tempCount += tempLocation.length;
              }
            }
            if (courier !== undefined) {
              const temp = courier.split('=')?.[1] || '';
              if (temp.length > 0) {
                tempCourier = temp.split(',');
                tempCount += tempLocation.length;
              }
            }
            if (lowestPrice !== undefined) tempCount += 1;
            if (highestPrice !== undefined) tempCount += 1;
          }

          setFilterCount(tempCount);

          res.forEach((it, i) => {
            if (i === 0)
              setFilterLocation({
                ...it,
                data: it.data
                  .map((it2) => {
                    let isExist = false;
                    if (it2.id === 'jabodetabek' && jabodetabek) isExist = true;
                    else {
                      const find = tempLocation.findIndex((it3) => `${it3}` === `${it2.id}`);
                      if (find > -1) isExist = true;
                    }

                    return {
                      ...it2,
                      isActive: isExist,
                    };
                  })
                  .sort((a, b) => Number(b?.isActive || false) - Number(a?.isActive || false)),
              });
            else if (i === 1)
              setFilterCategory({
                ...it,
                data: it.data
                  .map((it2) => {
                    let isExist = false;
                    const find = tempCategory.findIndex((it3) => `${it3}` === `${it2.id}`);
                    if (find > -1) isExist = true;

                    return {
                      ...it2,
                      isActive: isExist,
                    };
                  })
                  .sort((a, b) => Number(b?.isActive || false) - Number(a?.isActive || false)),
              });
            else if (i === 2)
              setFilterPaymentMethod({
                ...it,
                data: it.data
                  .map((it2) => {
                    return {
                      ...it2,
                      isActive: tempPayment,
                    };
                  })
                  .sort((a, b) => Number(b?.isActive || false) - Number(a?.isActive || false)),
              });
            else if (i === 3)
              setFilterCourier({
                ...it,
                data: it.data
                  .map((it2) => {
                    let isExist = false;
                    const find = tempCourier.findIndex((it3) => `${it3}` === `${it2.id}`);
                    if (find > -1) isExist = true;

                    return {
                      ...it2,
                      isActive: isExist,
                    };
                  })
                  .sort((a, b) => Number(b?.isActive || false) - Number(a?.isActive || false)),
              });
          });

          dispatch(saveSearchUtil(res));
        }
      },
      onError: () => {
        resetStateFilterUtil();
      },
    });
  };

  const dispatch = useAppDispatch();
  const searchData = useAppSelector((state) => state.SearchProduct.data);
  const filterSort = useAppSelector((state) => state.SearchSort.data);
  const filterUtil = useAppSelector((state) => state.SearchUtil.data);
  const [selectedSort, setSelectedSort] = useState<Product.FilterSort>();
  const [filterLocation, setFilterLocation] = useState<Product.Filter>();
  const [filterCategory, setFilterCategory] = useState<Product.Filter>();
  const [filterPaymentMethod, setFilterPaymentMethod] = useState<Product.Filter>();
  const [filterCourier, setFilterCourier] = useState<Product.Filter>();
  const [filterCount, setFilterCount] = useState<number>(0);
  const [filterMode, setFilterMode] = useState<{
    type: number;
    isFull: boolean;
    data: Product.Filter | undefined;
  }>({
    type: 0,
    isFull: false,
    data: undefined,
  });
  const [filterMinMax, setFilterMinMax] = useState<number[]>([0, 0]);
  const [query, setQuery] = useState<string>('');
  const filterType = {
    LOCATION: 0,
    CATEGORY: 1,
    PAYMENT: 2,
    COURIER: 3,
  };

  const updateFilter = (index: number, filter: Product.Filter): Product.Filter => {
    const temp = [...filter.data];
    if (temp[index].isActive) {
      temp[index].isActive = false;
    } else temp[index].isActive = true;

    const sorted = temp.sort((a, b) => Number(b?.isActive || false) - Number(a?.isActive || false));

    return {
      ...filter,
      data: sorted,
    };
  };

  const findIndexFilter = (id: string | number, filter: Product.Filter): number => {
    const index = filter.data.findIndex((it) => it.id === id);
    return index;
  };

  const chooseFilter = (type: number, id: string | number) => {
    if (type === filterType.LOCATION && filterLocation !== undefined) {
      const index = findIndexFilter(id, filterLocation);
      if (index > -1) {
        const temp = updateFilter(index, filterLocation);
        setFilterLocation(temp);
        if (filterMode.isFull) setFilterMode({ ...filterMode, data: temp });
      }
    } else if (type === filterType.CATEGORY && filterCategory !== undefined) {
      const index = findIndexFilter(id, filterCategory);
      if (index > -1) {
        const temp = updateFilter(index, filterCategory);
        setFilterCategory(temp);
        if (filterMode.isFull) setFilterMode({ ...filterMode, data: temp });
      }
    } else if (type === filterType.PAYMENT && filterPaymentMethod !== undefined) {
      const index = findIndexFilter(id, filterPaymentMethod);
      if (index > -1) {
        const temp = updateFilter(index, filterPaymentMethod);
        setFilterPaymentMethod(temp);
        if (filterMode.isFull) setFilterMode({ ...filterMode, data: temp });
      }
    } else if (type === filterType.COURIER && filterCourier !== undefined) {
      const index = findIndexFilter(id, filterCourier);
      if (index > -1) {
        const temp = updateFilter(index, filterCourier);
        setFilterCourier(temp);
        if (filterMode.isFull) setFilterMode({ ...filterMode, data: temp });
      }
    }
  };

  const resetFilter = () => {
    if (filterUtil !== null) {
      filterUtil.forEach((it, i) => {
        if (i === 0) setFilterLocation(it);
        else if (i === 1) setFilterCategory(it);
        else if (i === 2) setFilterPaymentMethod(it);
        else if (i === 3) setFilterCourier(it);
      });
      setFilterMinMax([0, 0]);
    }
  };

  const saveToTemp = (params: string) => {
    if (params.length > 0) dispatch(saveSearchProduct(params));
    else dispatch(removeSearchProduct());
  };

  return {
    filterSort,
    filterUtil,
    filterType,
    filterCategory,
    filterCourier,
    filterLocation,
    filterPaymentMethod,
    filterMode,
    filterMinMax,
    selectedSort,
    query,
    filterCount,
    searchData,
    resetFilter,
    setFilterCount,
    setSelectedSort,
    setQuery,
    setFilterMinMax,
    setFilterMode,
    chooseFilter,
    saveToTemp,
    onGetFilterSort,
    onGetFilterUtil,
    isLoadingFilterSort,
    isLoadingFilterUtil,
  };
};

export default useSearchFilter;
