import { deleteFavProduct, getMyFavoriteApi, getUserData, postInputWa, verifyWaApi } from '@/lib/api/favorite';
import { useMutation, useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@/lib/hooks/utils/useRedux';
import { saveUserData } from '@/store/user-data/action';
import { RootState } from '@/store';
import { version } from '@/package.json';
import { verifyWaFormat } from '@/utils';
import { useRouter } from 'next/router';
import { LIMIT_FAVORITE_LIST } from '@/constant';
import useEventLog from '@/lib/hooks/utils/useEventLog';

const useFavorite = () => {
  const {
    isLoading: isLoadingUserData,
    mutate: mutationUserData,
    reset: resetStateUserData,
  } = useMutation(getUserData);
  const { isLoading: isLoadingSubmitWa, mutate: mutationPostWa, reset: resetStateSubmitWa } = useMutation(postInputWa);
  const {
    isLoading: isLoadingSubmitToken,
    mutate: mutationVerifyWa,
    reset: resetStateVerifyWa,
  } = useMutation(verifyWaApi);
  const {
    isLoading: isLoadingDeleteProduct,
    mutate: mutationDeleProd,
    reset: resetStateDeleteProduct,
  } = useMutation(deleteFavProduct);
  const [indexProduct, setIndexProduct] = React.useState<number | null>();
  const [showAlertDelete, setShowAlertDelete] = React.useState<boolean>(false);
  const [selectFavProduct, setSelectFavProduct] = React.useState<number | undefined>(undefined);
  const [openModalWa, setOpenModalWa] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState<string>('');
  const [myOtp, setMyOpt] = React.useState<string>('');
  const dispatch = useAppDispatch();
  const { LOGS, writeLog } = useEventLog();

  const [param] = React.useState<Favorite.RequestListFavProps>({
    page: 1,
    limit: LIMIT_FAVORITE_LIST,
  });
  const userData = useAppSelector<FavoriteRedux.FavoriteReduxProps>(
    (state: RootState) => (state.UserData && state.UserData.user) || {},
  );
  const router = useRouter();
  const [inputWa, setInputWa] = React.useState<string>('');
  const toggleModalWa = () => setOpenModalWa((prevState) => !prevState);
  const [mode, setMode] = React.useState<'input_otp' | 'input_wa'>('input_wa');
  const handleInputWa = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputWa(value);
  };
  const getInitialUserData = () => {
    if (Object.keys(userData).length > 0) {
      return setLoading(false);
    }
    handleGetUser();
  };

  const handleGetUser = () => {
    mutationUserData(undefined, {
      onSuccess: (response) => {
        dispatch(saveUserData(response.data));
        setLoading(false);
      },
      onError: () => {
        resetStateUserData();
      },
    });
  };
  const toggleDeleteAlert = (id?: number, index?: number | null, event?: MouseEvent): void => {
    event?.stopPropagation();
    if (index) {
      setIndexProduct(index);
    }
    setShowAlertDelete((prevState) => !prevState);
    if (id) setSelectFavProduct(id);
  };

  const goToProductDetail = (id: string, event?: MouseEvent) => {
    event?.stopPropagation();
    router.push({ pathname: '/product/detail', search: `?product_id=${id}` });
  };

  const onSubmitWa = (callback?: () => void) => {
    const payload: Auth.RequestOtp = {
      value: verifyWaFormat(inputWa),
      source: 'verify_whatsapp',
      type: 'whatsapp',
      device_information: {
        app_version: version,
        brand: 'web',
        device_id: 'web',
        api_level: '31',
      },
    };
    mutationPostWa(payload, {
      onSuccess: (response) => {
        setMode('input_otp');
        setMyOpt('');
        setToken(response.data.token);
        if (callback) callback();
      },
      onError: () => {
        resetStateSubmitWa();
      },
    });
  };

  const onVerifyOtp = () => {
    const payload: Auth.VerifyOtp = {
      code: myOtp,
      token,
    };
    mutationVerifyWa(payload, {
      onSuccess: () => {
        // Setup Analytics Do Action Verify WA
        writeLog(LOGS.CLICK.CLICK_VERIFY_WHATSAPP, payload);
        handleGetUser();
        toggleModalWa();
      },
      onError: () => {
        resetStateVerifyWa();
      },
    });
  };

  const getMyFavorite = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useInfiniteQuery(
      ['productFavorites'],
      ({ pageParam = 1 }) => {
        return getMyFavoriteApi({ ...param, page: pageParam });
      },
      {
        getNextPageParam: (lastPage) => {
          if (lastPage?.current_page > lastPage?.max_pages) return;
          return lastPage?.current_page + 1 ?? undefined;
        },
        cacheTime: 60 * 1000 * 10,
        staleTime: 60 * 1000,
      },
    );
  };
  const { refetch, isError } = getMyFavorite();
  const onDeleteProduct = () => {
    mutationDeleProd(selectFavProduct, {
      onSuccess: async () => {
        // Setup Analytics Do Action Delete Product Favorite
        writeLog(LOGS.CLICK.CLICK_FAVORITE_DELETE, {
          product_id: selectFavProduct,
        });
        toggleDeleteAlert();
        await refetch({
          refetchPage: (lastPage: any, index) => {
            const myPage = Math.floor(Number(router.query.index) / param.limit);
            return index === myPage;
          },
        });
        router.back();
      },
      onError: () => {
        resetStateDeleteProduct();
      },
    });
  };

  const onDeleteProductParent = () => {
    mutationDeleProd(selectFavProduct, {
      onSuccess: async () => {
        // Setup Analytics Do Action Delete Product Favorite
        writeLog(LOGS.CLICK.CLICK_FAVORITE_DELETE, {
          product_id: selectFavProduct,
        });
        toggleDeleteAlert();
        await refetch({
          refetchPage: (lastPage: any, index) => {
            const myPage = Math.floor(Number(indexProduct) / param.limit);
            return index === myPage;
          },
        });
      },
    });
  };

  const goToEditPage = (id?: string | number, index?: number, event?: MouseEvent) => {
    event?.stopPropagation();
    if (id) {
      router.push({ pathname: `/favorite/${String(index)}/${id}` });
    }
  };

  return {
    openModalWa,
    toggleModalWa,
    inputWa,
    handleInputWa,
    getInitialUserData,
    userData,
    onSubmitWa,
    mode,
    setMode,
    isLoadingSubmitWa,
    isLoadingUserData,
    loading,
    setMyOpt,
    onVerifyOtp,
    myOtp,
    param,
    isLoadingSubmitToken,
    getMyFavorite,
    toggleDeleteAlert,
    showAlertDelete,
    selectFavProduct,
    onDeleteProduct,
    isLoadingDeleteProduct,
    goToEditPage,
    refetch,
    onDeleteProductParent,
    goToProductDetail,
    isError,
  };
};

export default useFavorite;
