import STORAGE from '@/constant/storage';

export const saveAddressIntro = (payload: Address.FormAddressProps) => ({
  type: STORAGE.ADDRESS_INTRO,
  payload,
});

export const removeAddressIntro = () => ({
  type: STORAGE.ADDRESS_INTRO,
  payload: null,
});

export const removeLocationAddition = (payload: Partial<Address.FormAddressProps>) => ({
  type: STORAGE.ADDRESS_INTRO,
  payload: payload,
});
