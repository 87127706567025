import STORAGE from '@/constant/storage';

export const savePreCheckout = (payload: Order.PreCheckoutResponseProps) => ({
  type: STORAGE.PRE_CHECKOUT,
  payload,
});

export const removePreCheckout = () => ({
  type: STORAGE.PRE_CHECKOUT,
  payload: null,
});
