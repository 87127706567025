import { ComponentSingleStyleConfig } from '@chakra-ui/theme';

const Input: ComponentSingleStyleConfig = {
  baseStyle: {},
  defaultProps: {
    focusBorderColor: 'primary.default',
  },
};

export default Input;
