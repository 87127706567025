import { Colors } from '@chakra-ui/theme';

interface colorTheme {
  primary: {
    default: string;
    pink: string;
    purple: string;
    whatsapp: string;
    green: string;
    line: string;
    titleText: string;
    text: string;
    grayText: string;
  };
}

const colors: Colors & colorTheme = {
  primary: {
    default: '#173C82',
    pink: '#e64f87',
    purple: '#6600d0',
    whatsapp: '#25D366',
    green: '#20a251',
    line: '#f4f4f4',
    titleText: '#43455c',
    text: '#4a4a4a',
    grayText: '#9b9b9b',
  },
  secondary: {
    blue: '#1a69d5',
    green: '#2b814a',
    red: '#d0021b',
  },
  gray: {
    dark: '#4a4a4a',
    deep: '#4a4a4a',
    light: '#9b9b9b',
  },
  light: {
    gray: '#c9c6c6',
    deep: '#a9a6a6',
    dark: '#999696',
    main: '#e9e6e6',
    blue: '#f1f5f8',
  },
  tertiary: {
    default: '#0078CE',
    red: '#F44336',
    orange: '#FF931E',
  },
};

export default colors;
