import { useCallback, useState } from 'react';

export const useCountdown = (initialValue: number) => {
  const [countdown, setCountdown] = useState(initialValue);

  const startCountdown = useCallback(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 1) {
          return prevCountdown - 1;
        }
        clearInterval(interval);
        return 0;
      });
    }, 1000);
  }, []);

  const resetCountdown = () => {
    setCountdown(initialValue);
    startCountdown();
  };

  return { countdown, startCountdown, resetCountdown };
};
