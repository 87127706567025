import { SAVE_TOKO_DATA } from './actionTypes';

type MyStoreInitialState = {
  toko: Admin.MyStore | undefined;
};

export const saveTokoData = (payload: MyStoreInitialState) => ({
  type: SAVE_TOKO_DATA,
  payload,
});
