import React, { Fragment, useState, useEffect, ChangeEvent } from 'react';
import { useRouter } from 'next/router';
import qs from 'query-string';
import {
  Box,
  Text,
  SimpleGrid,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { BsFilter } from 'react-icons/bs';

import { BottomSheet, Checkbox } from '@/components';
import { useHome } from '@/hooks/app/home';

import Locations from './parts/locations';
import Categories from './parts/categories';

import { parserNumber, numberFormater } from '@/utils';
import useEventLog from '@/lib/hooks/utils/useEventLog';

const Filter: React.FC<{ onCustomClickOpen?: () => void; onCustomClickClose?: () => void }> = ({
  onCustomClickOpen,
  onCustomClickClose,
}): JSX.Element => {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { LOGS, writeLog } = useEventLog();
  const toast = useToast();

  const locationQuery = router.query.province_id
    ? String(router.query.province_id).split(',').map(Number).filter(Boolean)
    : [];
  const categoryQuery = router.query.category1 ? String(router.query.category1).split(',').filter(Boolean) : [];
  const courierQuery = router.query.courier ? String(router.query.courier).split(',').filter(Boolean) : [];
  const paymentMethodQuery = router.query.payment_method
    ? String(router.query.payment_method).split(',').map(Number).filter(Boolean)
    : [];

  const jabodetabekQuery = router.query.jabodetabek ? Boolean(router.query.jabodetabek) : false;
  const lowPriceQuery = router.query.lowest_price ? String(router.query.lowest_price) : '';
  const highPriceQuery = router.query.highest_price ? String(router.query.highest_price) : '';

  const [filterData, setFilterData] = useState<Home.ProductFilter>();

  const [locationSelected, setLocationSelected] = useState<any[]>([]);
  const [categorySelected, setCategorySelected] = useState<any[]>([]);
  const [courierSelected, setCourierSelected] = useState<any[]>([]);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<any[]>([]);
  const [isJabodetabek, setIsJabodetabek] = useState<boolean>(false);
  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');
  const [showLocations, setShowLocations] = useState<boolean>(false);
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [showCouriers, setShowCouriers] = useState<boolean>(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState<boolean>(false);

  const { onGetProductFilter } = useHome();

  const { isFetching } = onGetProductFilter({
    option: {
      onSuccess: (data) => {
        setFilterData(data);
      },
      enabled: isOpen && !filterData,
    },
  });

  useEffect(() => {
    if (router.isReady) {
      setLocationSelected(locationQuery);
      setCategorySelected(categoryQuery);
      setCourierSelected(courierQuery);
      setPaymentMethodSelected(paymentMethodQuery);
      setIsJabodetabek(jabodetabekQuery);
      setMinPrice(lowPriceQuery);
      setMaxPrice(highPriceQuery);
    }
  }, [router.isReady, router.query]);

  useEffect(() => {
    return () => {
      setLocationSelected([]);
      setCategorySelected([]);
      setCourierSelected([]);
      setPaymentMethodSelected([]);
      setIsJabodetabek(false);
      setMinPrice('');
      setMaxPrice('');
    };
  }, [JSON.stringify(router.query.category)]);

  const countSelected = () => {
    const filterValidLocationID = locationSelected.filter((item) => item !== 0);
    return (
      filterValidLocationID.length +
      categorySelected.length +
      courierSelected.length +
      paymentMethodSelected.length +
      (isJabodetabek ? 1 : 0) +
      (minPrice ? 1 : 0) +
      (maxPrice ? 1 : 0)
    );
  };

  const onShowLocations = () => {
    setShowLocations(true);
    setShowCategories(false);
    setShowCouriers(false);
    setShowPaymentMethods(false);
  };

  const onShowCategories = () => {
    setShowCategories(true);
    setShowLocations(false);
    setShowCouriers(false);
    setShowPaymentMethods(false);
  };

  const onShowCouriers = () => {
    setShowCouriers(true);
    setShowLocations(false);
    setShowCategories(false);
    setShowPaymentMethods(false);
  };

  const onShowPaymentMethods = () => {
    setShowPaymentMethods(true);
    setShowLocations(false);
    setShowCategories(false);
    setShowCouriers(false);
  };

  const onResetSelected = () => {
    setLocationSelected([]);
    setCategorySelected([]);
    setCourierSelected([]);
    setPaymentMethodSelected([]);
    setIsJabodetabek(false);
    setMinPrice('');
    setMaxPrice('');
  };

  const onBackFilter = () => {
    setShowLocations(false);
    setShowCategories(false);
    setShowCouriers(false);
    setShowPaymentMethods(false);
  };

  const onApplyFilter = () => {
    const locationParams = qs.stringify({ province_id: locationSelected.filter(Boolean) }, { arrayFormat: 'comma' });
    const categoryParams = qs.stringify({ category1: categorySelected }, { arrayFormat: 'comma' });
    const courierParams = qs.stringify({ courier: courierSelected }, { arrayFormat: 'comma' });
    const paymentMethodParams = qs.stringify({ payment_method: paymentMethodSelected }, { arrayFormat: 'comma' });
    const lowestPriceParams = qs.stringify({ lowest_price: minPrice });
    const highestPriceParams = qs.stringify({ highest_price: maxPrice });
    const filterParams = qs.stringify({
      filter:
        locationSelected.filter(Boolean).length ||
        categorySelected.length ||
        courierSelected.length ||
        paymentMethodSelected.length ||
        isJabodetabek ||
        minPrice ||
        maxPrice
          ? true
          : false,
    });

    const paramsData = qs.stringify(
      {
        ...router.query,
        ...qs.parse(locationParams),
        ...qs.parse(categoryParams),
        ...qs.parse(courierParams),
        ...qs.parse(paymentMethodParams),
        jabodetabek: isJabodetabek,
        ...qs.parse(lowestPriceParams),
        ...qs.parse(highestPriceParams),
        ...qs.parse(filterParams),
      },
      { arrayFormat: 'comma' },
    );

    const params = qs.parse(paramsData);
    if (locationSelected.every((item) => item === 0) || !locationSelected.length) delete params.province_id;
    if (!categorySelected.length) delete params.category1;
    if (!courierSelected.length) delete params.courier;
    if (!paymentMethodSelected.length) delete params.payment_method;
    if (!isJabodetabek) delete params.jabodetabek;
    if (!minPrice) delete params.lowest_price;
    if (!maxPrice) delete params.highest_price;
    if (
      !locationSelected.filter(Boolean).length &&
      !categorySelected.length &&
      !courierSelected.length &&
      !paymentMethodSelected.length &&
      !isJabodetabek &&
      !minPrice &&
      !maxPrice
    )
      delete params.filter;

    if (Number(maxPrice) < Number(minPrice)) {
      toast({
        id: 'error',
        description: 'Harga maksimal tidak boleh kurang dari harga minimal',
        status: 'error',
        duration: 3000,
        isClosable: true,
        containerStyle: {
          fontSize: 'xs',
        },
      });
    } else {
      writeLog(LOGS.CLICK.CLICK_FILTER_PRODUCT, {
        query: qs.stringify(params),
      });

      router.push({
        search: qs.stringify(params),
      });

      onClose();
      onBackFilter();
    }
  };

  const onSelectFilter = (
    event: ChangeEvent<HTMLInputElement>,
    section: 'Lokasi Supplier' | 'Kategori' | 'Metode Pembayaran' | 'Kurir',
  ) => {
    if (event.target.value !== 'jabodetabek' || Number(event.target.value) !== 0) {
      if (event.target.checked) {
        switch (section) {
          case 'Lokasi Supplier':
            setLocationSelected((previousState) => [...previousState, Number(event.target.value)]);
            break;
          case 'Kategori':
            setCategorySelected((previousState) => [...previousState, event.target.value]);
            break;
          case 'Metode Pembayaran':
            setPaymentMethodSelected((previousState) => [...previousState, Number(event.target.value)]);
            break;
          case 'Kurir':
            setCourierSelected((previousState) => [...previousState, event.target.value]);
            break;
          default:
            break;
        }
      } else {
        switch (section) {
          case 'Lokasi Supplier':
            setLocationSelected(locationSelected.filter((item) => item !== Number(event.target.value)));
            break;
          case 'Kategori':
            setCategorySelected(categorySelected.filter((item) => item !== event.target.value));
            break;
          case 'Metode Pembayaran':
            setPaymentMethodSelected(paymentMethodSelected.filter((item) => item !== Number(event.target.value)));
            break;
          case 'Kurir':
            setCourierSelected(courierSelected.filter((item) => item !== event.target.value));
            break;
          default:
            break;
        }
      }
    }

    if (Number(event.target.value) === 0) {
      if (!isJabodetabek) {
        setIsJabodetabek(true);
      } else {
        setIsJabodetabek(false);
      }
    }
  };

  const onCloseBottomSheet = () => {
    setLocationSelected(locationQuery);
    setCategorySelected(categoryQuery);
    setCourierSelected(courierQuery);
    setPaymentMethodSelected(paymentMethodQuery);
    setIsJabodetabek(jabodetabekQuery);
    onCustomClickClose && onCustomClickClose();
    onClose();
  };

  const _location = () => {
    return filterData?.product_filters
      ?.filter((type) => type.text === 'Lokasi Supplier')
      .map((item, index) => {
        return (
          <Fragment key={`location-${index}`}>
            <Box paddingX={1.5} marginBottom={4}>
              <Text fontSize="sm">{item.text}</Text>
            </Box>
            <SimpleGrid columns={2} spacing={2} paddingX={1}>
              {item.data
                .sort((a, b) => locationSelected.indexOf(b.id) - locationSelected.indexOf(a.id))
                .map((item, index) => {
                  return (
                    <Checkbox
                      key={`location-item-${item.id}-${index}`}
                      id={item.id.toString()}
                      label={item.name}
                      value={item.id}
                      isChecked={locationSelected.includes(item.id) || (item.id === 0 && isJabodetabek) ? true : false}
                      defaultChecked={
                        locationSelected.includes(item.id) || (item.id === 0 && isJabodetabek) ? true : false
                      }
                      onChange={(event) => onSelectFilter(event, 'Lokasi Supplier')}
                    />
                  );
                })
                .slice(0, 4)}
            </SimpleGrid>
            <Box display="flex" justifyContent="center" marginY={2}>
              <Button border="none" background="white" height="fit-content" padding={2} onClick={onShowLocations}>
                <Text fontSize="xs" color="gray.dark">
                  Lihat Semua
                </Text>
              </Button>
            </Box>
          </Fragment>
        );
      });
  };

  const _category = () => {
    return filterData?.product_filters
      ?.filter((type) => type.text === 'Kategori')
      .map((item, index) => {
        return (
          <Fragment key={`category-${index}`}>
            <Box paddingX={1.5} marginBottom={4}>
              <Text fontSize="sm">{item.text}</Text>
            </Box>
            <SimpleGrid columns={2} spacing={2} paddingX={1}>
              {item.data
                .sort((a, b) => categorySelected.indexOf(b.id) - categorySelected.indexOf(a.id))
                .map((item, index) => {
                  return (
                    <Checkbox
                      key={`category-item-${item.id}-${index}`}
                      id={item.id.toString()}
                      label={item.name}
                      isChecked={categorySelected.includes(item.id)}
                      defaultChecked={categorySelected.includes(item.id)}
                      value={item.id}
                      onChange={(event) => onSelectFilter(event, 'Kategori')}
                    />
                  );
                })
                .slice(0, 4)}
            </SimpleGrid>
            <Box display="flex" justifyContent="center" marginY={2}>
              <Button border="none" background="white" height="fit-content" padding={2} onClick={onShowCategories}>
                <Text fontSize="xs" color="gray.dark">
                  Lihat Semua
                </Text>
              </Button>
            </Box>
          </Fragment>
        );
      });
  };

  const _paymentMethod = () => {
    return filterData?.product_filters
      ?.filter((type) => type.text === 'Metode Pembayaran')
      .map((item, index) => {
        return (
          <Fragment key={`payment-${index}`}>
            <Box paddingX={1.5} marginBottom={4}>
              <Text fontSize="sm">{item.text}</Text>
            </Box>
            <SimpleGrid columns={2} spacing={2} paddingX={1}>
              {item.data
                .map((item, index) => {
                  return (
                    <Checkbox
                      key={`payment-item-${item.id}-${index}`}
                      id={item.id.toString()}
                      label={item.name}
                      value={item.id}
                      isChecked={paymentMethodSelected.includes(item.id)}
                      defaultChecked={paymentMethodSelected.includes(item.id)}
                      onChange={(event) => onSelectFilter(event, 'Metode Pembayaran')}
                    />
                  );
                })
                .slice(0, 4)}
            </SimpleGrid>
          </Fragment>
        );
      });
  };

  const _courier = () => {
    return filterData?.product_filters
      ?.filter((type) => type.text === 'Kurir')
      .map((item, index) => {
        return (
          <Fragment key={`courier-${index}`}>
            <Box paddingX={1.5} marginBottom={4}>
              <Text fontSize="sm">{item.text}</Text>
            </Box>
            <SimpleGrid columns={2} spacing={2} paddingX={1}>
              {item.data
                .map((item, index) => {
                  return (
                    <Checkbox
                      key={`courier-item-${item.id}-${index}`}
                      id={item.id.toString()}
                      label={item.name}
                      isChecked={courierSelected.includes(item.name)}
                      defaultChecked={courierSelected.includes(item.name)}
                      value={item.name}
                      onChange={(event) => onSelectFilter(event, 'Kurir')}
                    />
                  );
                })
                .slice(0, 4)}
            </SimpleGrid>
          </Fragment>
        );
      });
  };

  const _price = () => {
    return (
      <>
        <SimpleGrid columns={2} spacing={2}>
          <Box paddingX={1.5}>
            <Text mb={1} fontSize="sm">
              Harga Minimum (Rp)
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none" color="gray.dark" fontSize="md">
                Rp
              </InputLeftElement>
              <Input
                type="number"
                placeholder="Harga Min"
                value={numberFormater(Number(minPrice))}
                fontSize="md"
                onChange={(event) => setMinPrice(parserNumber(event.target.value))}
              />
            </InputGroup>
          </Box>
          <Box paddingX={1.5}>
            <Text mb={1} fontSize="sm">
              Harga Maksimum (Rp)
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none" color="gray.dark" fontSize="md">
                Rp
              </InputLeftElement>
              <Input
                type="number"
                placeholder="Harga Maks"
                value={numberFormater(Number(maxPrice))}
                fontSize="md"
                onChange={(event) => setMaxPrice(parserNumber(event.target.value))}
              />
            </InputGroup>
          </Box>
        </SimpleGrid>
      </>
    );
  };

  return (
    <>
      <Box
        display="flex"
        paddingY={2}
        paddingX={3}
        background="#F2F2F2"
        borderRadius="3xl"
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          onOpen();
          onCustomClickOpen && onCustomClickOpen();
        }}
      >
        <BsFilter size={20} />
        <Box display="flex" alignItems="center">
          <Text fontSize={14} marginLeft={1.5} textColor="black">
            Filter
          </Text>
          {countSelected() > 0 && (
            <Box
              as="div"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={5}
              height={5}
              borderRadius="full"
              marginLeft={2}
              background="primary.default"
              color="white"
              fontSize="xs"
            >
              {countSelected()}
            </Box>
          )}
        </Box>
      </Box>

      <BottomSheet
        isOpen={isOpen}
        closeOnEsc={false}
        variant={'bottomSheet'}
        onClose={() => {
          onCustomClickClose && onCustomClickClose();
          onCloseBottomSheet();
          onBackFilter();
        }}
      >
        {!showLocations && !showCategories ? (
          <BottomSheet.Title>
            <Box display="flex" alignItems="center">
              <BsFilter size={22} />
              <Text marginLeft={2}>Filter</Text>
            </Box>
          </BottomSheet.Title>
        ) : (
          <BottomSheet.Title withBackButton onBack={onBackFilter}>
            <Box display="flex" alignItems="center">
              <Text marginLeft={2}>{showLocations && 'Lokasi Supplier'}</Text>
              <Text marginLeft={2}>{showCategories && 'Kategori'}</Text>
            </Box>
          </BottomSheet.Title>
        )}
        <Box maxHeight="sm" overflowY="auto">
          {showLocations && filterData && (
            <Locations
              product_filters={filterData?.product_filters}
              dataSelected={locationSelected}
              isJabodetabek={isJabodetabek}
              onSelect={onSelectFilter}
            />
          )}
          {showCategories && filterData && (
            <Categories
              product_filters={filterData?.product_filters}
              dataSelected={categorySelected}
              onSelect={onSelectFilter}
            />
          )}

          {isFetching && (
            <Box textAlign="center">
              <Spinner color="primary.default" />
            </Box>
          )}

          {!isFetching && !showLocations && !showCategories && (
            <BottomSheet.Body>
              <Box marginBottom={5}>
                <Box>{_location()}</Box>
                <Box marginTop={7}>{_category()}</Box>
                <Box marginTop={7}>{_paymentMethod()}</Box>
                <Box marginTop={7}>{_price()}</Box>
                <Box marginTop={7}>{_courier()}</Box>
              </Box>
            </BottomSheet.Body>
          )}
        </Box>
        {!isFetching && (
          <Box marginBottom={5}>
            <SimpleGrid columns={2} spacing={2} paddingX={7} marginTop={7}>
              <Button background="white" borderColor="#9b9b9b" border="1px" onClick={onResetSelected}>
                Hilangkan
              </Button>
              <Button
                background="primary.default"
                color="white"
                _hover={{ background: 'primary.default' }}
                onClick={onApplyFilter}
              >
                Terapkan
              </Button>
            </SimpleGrid>
          </Box>
        )}
      </BottomSheet>
    </>
  );
};

export default Filter;
