import axios from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const getAutoCompleteProduct = async (query: string) => {
  try {
    const url = API.PRODUCT_SEARCH_AUTOCOMPLETE(query);
    const { data: response } = await fetcher.get(url);
    const data: string[] = response?.data?.suggestions;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getProductSearch = async (request: {
  query: string | undefined;
  page: number;
  keyword: string;
  limit?: number;
}) => {
  try {
    const url = API.PRODUCT_SEARCH(request);
    const { data: response } = await fetcher.get(url);
    const data: Home.ProductItem[] = response?.data?.data;
    return {
      data: data,
      currentPage: response?.data?.current_page || 1,
      maxPage: response?.data?.max_page || 1,
    };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getSupplierSearch = async (query: string) => {
  try {
    const url = API.SUPPLIER_SEARCH(query);
    const { data: response } = await fetcher.get(url);
    const data: Supplier.SupplierSearch[] = response?.data?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
