import React from 'react';

function Icon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5 3.772c-2.204 0-3.98 1.77-3.98 3.94 0 2.168 1.776 3.938 3.98 3.938 2.204 0 3.98-1.77 3.98-3.939 0-2.17-1.776-3.939-3.98-3.939zm-5.755 3.94C6.745 4.55 9.328 2 12.5 2c3.172 0 5.755 2.55 5.755 5.711 0 3.16-2.583 5.711-5.755 5.711-3.172 0-5.755-2.55-5.755-5.71zm-.417 9.078c1.639-.922 3.83-1.387 6.172-1.387 2.343 0 4.533.465 6.172 1.387 1.652.93 2.828 2.39 2.828 4.324a.887.887 0 01-1.775 0c0-1.104-.64-2.057-1.924-2.78-1.297-.73-3.163-1.159-5.301-1.159-2.138 0-4.004.429-5.3 1.159-1.285.723-1.925 1.676-1.925 2.78a.887.887 0 01-1.775 0c0-1.934 1.175-3.393 2.828-4.324z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
