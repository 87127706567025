import axios, { AxiosRequestConfig } from 'axios';
import { fetcher } from '@/utils';

export const getOrderDetail = async (id: string, params?: AxiosRequestConfig): Promise<{ data: Order.Detail }> => {
  try {
    const { data: response } = await fetcher.get('/v1/order' + `/${id}`, params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderDetailTransaction = async (
  id: string,
  params?: AxiosRequestConfig,
): Promise<{ data: Order.DetailTransaction }> => {
  try {
    const { data: response } = await fetcher.get('/v1/order/transaction' + `/${id}`, params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderDetailPDF = async (
  id: string,
  params?: AxiosRequestConfig,
): Promise<{ data: Order.DetailPDF }> => {
  try {
    const { data: response } = await fetcher.get('/v1/order/transaction/generate-pdf/' + id, params);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const postCancelOrder = async (
  params: AxiosRequestConfig | { transaction_id: string; order_id: string },
): Promise<void> => {
  try {
    const { data: response } = await fetcher.post('/v1/order/cancel', params);
    return { ...response, enableToast: true };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
