import STORAGE from '@/constant/storage';

export const savePreCheckoutInit = (payload: Order.PreCheckoutInit) => ({
  type: STORAGE.PRE_CHECKOUT_INIT,
  payload,
});

export const removePreCheckoutInit = () => ({
  type: STORAGE.PRE_CHECKOUT_INIT,
  payload: null,
});
