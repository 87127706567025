import React from 'react';
import { Box, Text, Image, Divider, Icon, Button } from '@chakra-ui/react';
import { BiTimeFive } from 'react-icons/bi';
import { isBefore, isEqual } from 'date-fns';

import { dateFormatter, currencyFormatter } from '@/utils';

interface CardOrderProps extends React.HTMLAttributes<HTMLDivElement> {
  item: Order.Item;
}

const CardOrder: React.FC<CardOrderProps> = ({ onClick, item }) => {
  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      backgroundColor="white"
      marginBottom={2}
      cursor="pointer"
      onClick={onClick}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        borderTopRadius="md"
        background={item.last_status.bg_color}
      >
        <Text fontSize="xs" color={item.last_status.text_color} fontWeight="bold">
          {dateFormatter(item.created_at, 'd MMM yyyy')}
        </Text>
        <Text fontSize="xs" color={item.last_status.text_color} fontWeight="bold">
          {item.last_status.text}
        </Text>
      </Box>
      <Box padding={3}>
        <Box display="flex" marginBottom={2.5}>
          <Box
            as="div"
            marginRight={3}
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
            width={'100%'}
            maxWidth={'40px'}
            height={'40px'}
            padding="0.5"
            borderRadius="xs"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={item.items[0].image}
              width="100%"
              height="100%"
              objectFit="contain"
              alt="img-product"
              loading="lazy"
              placeholder={`https://via.placeholder.com/40`}
              fallbackSrc={`https://via.placeholder.com/40`}
            />
          </Box>
          <Box as="div">
            <Text fontSize="xs" fontWeight="bold">
              {item.items[0].name}
            </Text>
            <Text fontSize="xs" color="gray.light">
              {item.items[0].quantity} Produk
            </Text>
          </Box>
        </Box>

        <Divider />
        <Box as="div" marginTop={2.5} display="flex" justifyContent="space-between">
          <Box id="order-info-price">
            <Text as="span" fontSize="xs" color="gray.light">
              Total Harga
            </Text>
            <Text as="span" fontSize="xs" fontWeight="bold" marginLeft={2}>
              {currencyFormatter(item.amount.paid)}
            </Text>
            {item.payment.method !== 'COD' &&
              item.payment.status === 'UNPAID' &&
              !item.last_status.text.includes('batal') &&
              (!isBefore(new Date(item.expired_at), new Date()) || isEqual(new Date(item.expired_at), new Date())) && (
                <Box id="order-info-countdown" display="flex" alignItems="center" marginTop={1.5}>
                  <Icon as={BiTimeFive} size={20} color="gray.light" />

                  <Text as="span" fontSize="xs" color="gray.light" marginLeft={1}>
                    Bayar Sebelum:{' '}
                    <Text as="span" color="primary.default" fontWeight="bold">
                      {dateFormatter(item.expired_at, 'd MMM yyyy')}
                    </Text>
                  </Text>
                </Box>
              )}
          </Box>

          {item.payment.method !== 'COD' &&
            item.payment.status === 'UNPAID' &&
            !item.last_status.text.includes('batal') &&
            (!isBefore(new Date(item.expired_at), new Date()) || isEqual(new Date(item.expired_at), new Date())) && (
              <Box marginTop="auto">
                <Button
                  id="order-info-paid"
                  background="primary.default"
                  color="white"
                  borderRadius="md"
                  width="full"
                  fontSize="xs"
                  fontWeight="bold"
                  // marginLeft={2}
                  _hover={{ background: 'primary.default' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(item.payment.url, '_self');
                  }}
                >
                  Bayar Sekarang
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardOrder;
