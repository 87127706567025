import { getAutoCompleteProduct, getProductSearch, getSupplierSearch } from '@/lib/api/product';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import useEventLog from '@/lib/hooks/utils/useEventLog';
import { useAppSelector } from '@/store';

// Product Detail Data
const useSearch = () => {
  const {
    mutate: mutationAutoCompleteProduct,
    isLoading: isLoadingAutoCompleteProduct,
    isError: isErrorAutoCompleteProduct,
    reset: resetStateAutoCompleteProduct,
  } = useMutation(getAutoCompleteProduct);
  const {
    mutate: mutationProductSearch,
    isLoading: isLoadingProductSearch,
    reset: resetStateProductSearch,
  } = useMutation(getProductSearch);
  const {
    mutate: mutationSupplierSearch,
    isLoading: isLoadingSupplierSearch,
    reset: resetStateSupplierSearch,
  } = useMutation(getSupplierSearch);
  const { LOGS, writeLog } = useEventLog();

  const onGetAutoCompleteProduct = (query: string) => {
    mutationAutoCompleteProduct(query, {
      onSuccess: (response) => {
        if (response !== undefined) {
          // Setup Analytics Elastic Search Hint
          setAutoCompletes(response);
          updateSearchMode(false);
        }
      },
      onError: () => {
        resetStateAutoCompleteProduct();
      },
    });
  };

  const onGetProductSearch = (query: string, page: number, keyword: string) => {
    mutationProductSearch(
      {
        query: query,
        page: page,
        keyword: keyword,
        limit: 20,
      },
      {
        onSuccess: (response) => {
          if (response !== undefined) {
            setAutoCompletes([]);
            setPage(response.currentPage);
            setMaxPage(response.maxPage);

            if (page > 1 && productList.length > 0) setProductList([...productList, ...response.data]);
            else {
              // Setup Analytics Elastic Search Hint
              writeLog(LOGS.ON_CHANGE.ON_CHANGE_SEARCH_AUTOCOMPLETE, {
                query: keyword,
              });

              setProductList(response.data);
            }

            updateSearchMode(true);
          }
        },
        onError: () => {
          resetStateProductSearch();
        },
      },
    );
  };

  const onGetSupplierSearch = (query: string) => {
    mutationSupplierSearch(query, {
      onSuccess: (response) => {
        if (response !== undefined) {
          if (query?.length <= 5) {
            writeLog(LOGS.ON_CHANGE.ON_CHANGE_SEARCH_SUPPLIER, {
              alias: query,
            });
          }
          setSupplierSearch(response);
        }
      },
      onError: () => {
        resetStateSupplierSearch();
      },
    });
  };

  const searchProductData = useAppSelector((state) => state.SearchProduct.data);
  const [autoCompletes, setAutoCompletes] = useState<string[]>([]);
  const [query, setQuery] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [isDoSearchProduct, setIsDoSearchProduct] = useState<boolean>(false);
  const [supplierSearch, setSupplierSearch] = useState<Supplier.SupplierSearch[]>([]);
  const [productList, setProductList] = useState<Home.ProductItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);

  const updateAutoCompleteData = (data: string[]) => {
    setAutoCompletes(data);
  };

  const updateQuery = (data: string) => {
    setQuery(data);
  };

  const updateSearchMode = (mode: boolean) => {
    setIsDoSearchProduct(mode);
  };

  const updateSupplierSearch = (data: Supplier.SupplierSearch[]) => {
    setSupplierSearch(data);
  };

  const updateProductList = (data: Home.ProductItem[]) => {
    setProductList(data);
  };

  return {
    autoCompletes,
    query,
    isDoSearchProduct,
    supplierSearch,
    productList,
    keyword,
    page,
    maxPage,
    searchProductData,
    setKeyword,
    updateAutoCompleteData,
    updateQuery,
    updateSearchMode,
    updateSupplierSearch,
    updateProductList,
    onGetAutoCompleteProduct,
    isLoadingAutoCompleteProduct,
    onGetProductSearch,
    isLoadingProductSearch,
    onGetSupplierSearch,
    isLoadingSupplierSearch,
    isErrorAutoCompleteProduct,
  };
};

export { useSearch };
