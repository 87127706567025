import axios from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const postProductToFavorite = async (request: Product.AddToFavoriteRequestProps) => {
  try {
    const url = API.PRODUCT_ADD_TO_FAVORITE;
    const { data: response } = await fetcher.post(url, request);
    const data: Product.AddToFavoriteResponseProps = response?.data?.product_reseller;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const deleteProductFromFavorite = async (productID: number) => {
  try {
    const url = API.PRODUCT_DELETE_FROM_FAVORITE(productID);
    const { data: response } = await fetcher.delete(url);
    const data: Product.AddToFavoriteResponseProps = response?.data?.product_reseller;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
