import { SAVE_USER_DATA, DESTROY_SESSION } from './actionTypes';

export const saveUserData = (payload: FavoriteRedux.FavoriteReduxProps) => ({
  type: SAVE_USER_DATA,
  payload,
});

export const onClear = () => ({
  type: DESTROY_SESSION,

  // Your code here...
});
