import React from 'react';
import { Input, Box } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';

interface SearchProp {
  handleBack?: () => void;
}

const Search: React.FC<SearchProp> = ({ handleBack }): JSX.Element => {
  const searchRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    searchRef.current?.focus();

    return () => {
      searchRef.current?.blur();
    };
  }, []);

  return (
    <Box
      position="fixed"
      width="full"
      height="full"
      maxWidth="md"
      top={0}
      bottom={0}
      backgroundColor="white"
      zIndex="overlay"
    >
      <Box as="div" display="flex" justifyContent="space-between" alignItems="center" padding={3}>
        <Box as="div" display="flex" alignItems="center" justifyContent="center" flex={1}>
          <FiArrowLeft size={25} onClick={handleBack} />
          <Input ref={searchRef} placeholder="Search" marginLeft={2} height={8} />
        </Box>
      </Box>
    </Box>
  );
};

export default Search;
