import { Styles } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: () => ({
    body: {
      margin: 0,
      backgroundColor: '#f6f9ff',
      color: '#4a4a4a',
      minHeight: '100vh',
      wordBreak: 'break-word',
      overflow: 'hidden',
      overflowY: 'auto',
      letterSpacing: 'normal',
      position: 'relative',
    },

    // '::-webkit-scrollbar': {
    //   display: 'none',
    // },

    '::WebkitOverflowScrolling': 'touch',

    '.swiper-pagination-bullet': {
      display: 'inline-block',
      borderRadius: '100%',
      width: '6px',
      height: '6px',
      textAlign: 'center',
      lineHeight: '30px',
      fontSize: '24px',
      opacity: '1',
      background: '#e6e6e6',
    },

    '.swiper-pagination-bullet-active': {
      opacity: '1',
      padding: '0 12px',
      borderRadius: '100px',
      background: '#173C82',
      color: 'rgb(255, 255, 255)',
    },
    shadow: {
      outline: 'primary.default !important',
    },
  }),
};
