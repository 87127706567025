import STORAGE from '@/constant/storage';

export const saveSelectedCart = (payload: any) => ({
  type: 'SelectedCart',
  payload,
});

export const removeSelectedCart = () => ({
  type: STORAGE.SELECTED_CART,
  payload: null,
});
