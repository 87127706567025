import useSearchFilter from '@/lib/hooks/utils/useSearchFilter';
import { currencyFormatter, numberFormater, parseCurrency } from '@/utils';
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Spacer,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Fragment, useEffect, useRef } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiCheck, BiFilter, BiSortAlt2 } from 'react-icons/bi';
import { FiArrowLeft } from 'react-icons/fi';
import BottomSheet from '../bottom-sheet';
import useEventLog from '@/lib/hooks/utils/useEventLog';

const SearchFilter: React.FC<{
  isWithCategory: boolean | false;
  onSubmit: (query: string) => void;
}> = ({ isWithCategory, onSubmit }): JSX.Element => {
  const { isOpen: isOpenSort, onOpen: onOpenSort, onClose: onCloseSort } = useDisclosure();
  const { isOpen: isOpenFilter, onOpen: onOpenFilter, onClose: onCloseFilter } = useDisclosure();
  const minimumRef = useRef<HTMLInputElement | null>(null);
  const maximumRef = useRef<HTMLInputElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const { LOGS, writeLog } = useEventLog();

  const {
    filterSort,
    filterCategory,
    filterCourier,
    filterLocation,
    filterPaymentMethod,
    filterType,
    filterMode,
    filterMinMax,
    selectedSort,
    filterUtil,
    query,
    filterCount,
    searchData,
    isLoadingFilterSort,
    isLoadingFilterUtil,
    resetFilter,
    setFilterCount,
    setQuery,
    setSelectedSort,
    setFilterMinMax,
    setFilterMode,
    chooseFilter,
    saveToTemp,
    onGetFilterSort,
    onGetFilterUtil,
  } = useSearchFilter();

  useEffect(() => {
    if (filterSort === null) onGetFilterSort();
    else setupFilter();
  }, [filterSort]);

  useEffect(() => {
    if (filterUtil === null) onGetFilterUtil();
    else setupFilter();
  }, [filterUtil]);

  const setupFilter = () => {
    const temp = searchData || '';
    if (temp.length > 0 && filterSort !== null) {
      const temp2 = temp.split('&');
      const sort = temp2.find((it) => it.includes('sort'));
      const lowestPrice = temp2.find((it) => it.includes('lowest_price'));
      const highestPrice = temp2.find((it) => it.includes('highest_price'));
      const tempMinMax = [0, 0];
      if (sort !== undefined) {
        const tempSort = filterSort.find((it) => it.query === sort.split('=')?.[1] || '');
        if (tempSort !== undefined) setSelectedSort(tempSort);
      }
      if (lowestPrice !== undefined) {
        tempMinMax[0] = Number(lowestPrice.split('=')?.[1] || '0');
        if (minimumRef.current) minimumRef.current.value = lowestPrice.split('=')?.[1] || '0';
      }
      if (highestPrice !== undefined) {
        tempMinMax[1] = Number(highestPrice.split('=')?.[1] || '0');
        if (maximumRef.current) maximumRef.current.value = highestPrice.split('=')?.[1] || '0';
      }
      setFilterMinMax(tempMinMax);
    }
  };

  const doUpdate = (selectedSort: Product.FilterSort | undefined) => {
    let params = '';
    let tempCount = 0;
    // Get Sort Util
    // Location
    if (filterLocation !== undefined) {
      const temp = filterLocation.data.filter((it) => it.isActive && it.name !== 'Jabodetabek');
      if (temp.length > 0) {
        params = params.concat('&', `province_id=${temp.map((it) => it.id).join(',')}`);
        tempCount += temp.length;
      }
      const isJabodetabek = filterLocation.data.find((it) => it.isActive && it.name === 'Jabodetabek');
      if (isJabodetabek !== undefined && isJabodetabek !== null) {
        params = params.concat('&', `jabodetabek=${true}`);
        tempCount += 1;
      }
    }
    // Category
    if (filterCategory !== undefined && isWithCategory) {
      const temp = filterCategory.data.filter((it) => it.isActive);
      if (temp.length > 0) {
        params = params.concat('&', `category1=${temp.map((it) => it.id).join(',')}`);
        tempCount += temp.length;
      }
    }
    // Payment
    if (filterPaymentMethod !== undefined) {
      const temp = filterPaymentMethod.data.filter((it) => it.isActive);
      if (temp.length > 0) {
        params = params.concat('&', `is_cod=${true}`);
        tempCount += temp.length;
      }
    }
    // Courier
    if (filterCourier !== undefined) {
      const temp = filterCourier.data.filter((it) => it.isActive);
      if (temp.length > 0) {
        params = params.concat('&', `courier=${temp.map((it) => it.id).join(',')}`);
        tempCount += temp.length;
      }
    }
    // Min Max
    if (filterMinMax[0] > 0) {
      params = params.concat('&', `lowest_price=${filterMinMax[0]}`);
      tempCount += 1;
    }
    if (filterMinMax[1] > 0) {
      params = params.concat('&', `highest_price=${filterMinMax[1]}`);
      tempCount += 1;
    }
    // Combine With Sort
    if (selectedSort !== undefined) params = params.concat('&', `sort=${selectedSort.query}`);
    setFilterCount(tempCount);
    onCloseFilter();
    onCloseSort();

    writeLog(LOGS.CLICK.CLICK_FILTER_PRODUCT, {
      query: params,
    });

    saveToTemp(params);

    // Do Update
    onSubmit(params);
  };

  const doReset = () => {
    let params = '';
    // Get Sort
    if (selectedSort !== undefined) params = params.concat('&', `sort=${selectedSort.query}`);
    // Do Reset
    if (minimumRef.current) minimumRef.current.value = '';
    if (maximumRef.current) maximumRef.current.value = '';
    setFilterCount(0);
    resetFilter();
    onCloseFilter();

    writeLog(LOGS.CLICK.CLICK_FILTER_PRODUCT, {
      query: params,
    });

    saveToTemp(params);

    onSubmit(params);
  };

  // Show Modal Sort
  const _showModalSort = () => {
    return (
      <BottomSheet isOpen={isOpenSort} variant={'bottomSheet'} onClose={onCloseSort}>
        <Box width={'full'}>
          <HStack width={'full'} borderBottomWidth={1} borderBottomColor={'light.main'} padding={3}>
            <Icon as={BiSortAlt2} fontSize={20} />
            <Text fontWeight={'bold'}>Urutkan</Text>
          </HStack>
          {isLoadingFilterSort && (
            <Center>
              <Spinner marginTop={32} />
            </Center>
          )}
          {filterSort !== null &&
            filterSort.map((it, i) => {
              return (
                <HStack
                  key={`filter-sort-${i}`}
                  width={'full'}
                  borderBottomWidth={1}
                  borderBottomColor={'light.main'}
                  padding={3}
                  onClick={() => {
                    setSelectedSort(it);
                    doUpdate(it);
                  }}
                >
                  <Image src={it.icon} width={6} />
                  <Text>{it.text}</Text>
                </HStack>
              );
            })}
        </Box>
      </BottomSheet>
    );
  };

  // Show Modal Filter
  const _showModalFilter = () => {
    return (
      <BottomSheet
        isOpen={isOpenFilter}
        variant={'bottomSheet'}
        onClose={() => {
          setFilterMode({ type: 0, isFull: false, data: undefined });
          if (searchRef.current) {
            searchRef.current.value = '';
            setQuery('');
          }
          onCloseFilter();
        }}
      >
        <Box width={'full'}>
          <HStack
            width={'full'}
            borderBottomWidth={1}
            borderBottomColor={'light.main'}
            padding={3}
            onClick={() => {
              if (filterMode.isFull) setFilterMode({ type: 0, isFull: false, data: undefined });
              if (searchRef.current) {
                searchRef.current.value = '';
                setQuery('');
              }
            }}
          >
            <Icon as={filterMode.isFull ? FiArrowLeft : BiFilter} fontSize={20} />
            <Text fontWeight={'bold'}>{filterMode.isFull ? filterMode?.data?.text || 'Filter' : 'Filter'}</Text>
          </HStack>
          {isLoadingFilterUtil && (
            <Center>
              <Spinner marginTop={32} />
            </Center>
          )}
          {filterMode.isFull ? (
            <Box width={'full'} maxHeight={'400px'} overflowY={'scroll'}>
              <Box paddingX={4} paddingY={4}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" height={8}>
                    <Icon as={AiOutlineSearch} color="gray.light" />
                  </InputLeftElement>
                  <Input
                    color="gray.light"
                    type="search"
                    placeholder="Cari Pesanan"
                    fontSize="sm"
                    height={8}
                    ref={(ref) => {
                      if (ref !== undefined && ref !== null) {
                        searchRef.current = ref;
                      }
                    }}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                </InputGroup>
              </Box>
              {filterMode.data !== undefined &&
                filterUtilItem(filterMode.type, filterMode.data, filterMode.data.data.length)}
            </Box>
          ) : (
            <Box width={'full'} maxHeight={'400px'} overflowY={'scroll'}>
              {filterLocation !== undefined && filterUtilItem(filterType.LOCATION, filterLocation, 4)}
              {isWithCategory && filterCategory !== undefined && filterUtilItem(filterType.CATEGORY, filterCategory, 4)}
              {filterCost()}
              {filterCourier !== undefined && filterUtilItem(filterType.COURIER, filterCourier, 4)}
              {filterPaymentMethod !== undefined && filterUtilItem(filterType.PAYMENT, filterPaymentMethod, 4)}
            </Box>
          )}
          <HStack gap={4} paddingX={4} paddingY={4} borderTopWidth={'1px'} borderTopColor={'light.main'}>
            <Button
              flex={1}
              size={'md'}
              colorScheme={'whiteAlpha'}
              borderWidth={'1px'}
              borderColor={'light.main'}
              textColor={'gray.500'}
              onClick={doReset}
            >
              Hilangkan
            </Button>
            <Button
              flex={1}
              size={'md'}
              variant={'ghost'}
              backgroundColor={'primary.default'}
              textColor={'white'}
              borderWidth={'1px'}
              borderColor={'pink'}
              _hover={{
                backgroundColor: 'primary.default',
                textColor: 'white',
              }}
              onClick={() => doUpdate(selectedSort)}
            >
              Terapkan
            </Button>
          </HStack>
        </Box>
      </BottomSheet>
    );
  };

  // Filter Maximum and Minimum Cost
  const filterCost = () => {
    return (
      <Box width={'full'}>
        <Box paddingX={4} paddingY={2}>
          <Text fontWeight={'bold'} fontSize={'14'}>
            Harga (Rp)
          </Text>
        </Box>
        <HStack paddingX={4} gap={2}>
          <Box flex={1}>
            <Input
              variant="flushed"
              fontSize={12}
              fontWeight={'semibold'}
              color={'gray.800'}
              placeholder={currencyFormatter(0)}
              defaultValue={filterMinMax[0] > 0 ? currencyFormatter(filterMinMax[0]) : ''}
              disabled={(filterMinMax[0] || 0) < 0}
              ref={(ref) => {
                if (ref !== undefined && ref !== null) {
                  minimumRef.current = ref;
                }
              }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  let temp = e.target.value;
                  if (temp.includes('Rp ')) {
                    temp = parseCurrency(temp);
                  } else {
                    temp = `Rp ${numberFormater(Number(temp))}`;
                    temp = parseCurrency(temp);
                  }

                  const tempMinMax = [...filterMinMax];
                  tempMinMax[0] = Number(temp);
                  e.target.value = `Rp ${numberFormater(Number(temp))}`;
                  setFilterMinMax(tempMinMax);
                }
              }}
            />
          </Box>
          <Box bgColor={'gray.400'} width={'8px'} height={'4px'}></Box>
          <Box flex={1}>
            <Input
              variant="flushed"
              fontSize={12}
              fontWeight={'semibold'}
              color={'gray.800'}
              placeholder={currencyFormatter(0)}
              defaultValue={filterMinMax[1] > 0 ? currencyFormatter(filterMinMax[1]) : ''}
              disabled={(filterMinMax[1] || 0) < 0}
              ref={(ref) => {
                if (ref !== undefined && ref !== null) {
                  maximumRef.current = ref;
                }
              }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  let temp = e.target.value;
                  if (temp.includes('Rp ')) {
                    temp = parseCurrency(temp);
                  } else {
                    temp = `Rp ${numberFormater(Number(temp))}`;
                    temp = parseCurrency(temp);
                  }

                  const tempMinMax = [...filterMinMax];
                  tempMinMax[1] = Number(temp);
                  e.target.value = `Rp ${numberFormater(Number(temp))}`;
                  setFilterMinMax(tempMinMax);
                }
              }}
            />
          </Box>
        </HStack>

        <Box paddingX={4} paddingY={2} />
      </Box>
    );
  };

  // Filter Util (Location, Category, Payment, and Courier)
  const filterUtilItem = (type: number, data: Product.Filter, limit: number) => {
    return (
      <Box width={'full'}>
        {limit === 4 && (
          <Box paddingX={4} paddingY={2}>
            <Text fontWeight={'bold'} fontSize={'14'}>
              {data.text}
            </Text>
          </Box>
        )}
        <SimpleGrid columns={2} spacing={2} paddingX={4}>
          {limit < data.data.length
            ? data.data
                .map((it, i) => {
                  return (
                    <HStack
                      key={`filter-util-${type}-${i}`}
                      width={'full'}
                      paddingX={3}
                      paddingY={2}
                      bgColor={it.isActive ? 'white' : 'gray.100'}
                      borderRadius={'4px'}
                      borderWidth={'1px'}
                      borderColor={it.isActive ? 'primary.default' : 'gray.100'}
                      cursor={'pointer'}
                      onClick={() => chooseFilter(type, it.id)}
                    >
                      <Text fontSize={13}>{it.name}</Text>
                      <Spacer />
                      {it.isActive && <Icon as={BiCheck} textColor={'primary.default'} />}
                    </HStack>
                  );
                })
                .slice(0, limit)
            : data.data
                .filter((it) => it.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
                .map((it, i) => {
                  return (
                    <HStack
                      key={`filter-util-${type}-${i}`}
                      width={'full'}
                      paddingX={3}
                      paddingY={2}
                      bgColor={it.isActive ? 'white' : 'gray.100'}
                      borderRadius={'4px'}
                      borderWidth={'1px'}
                      borderColor={it.isActive ? 'primary.default' : 'gray.100'}
                      cursor={'pointer'}
                      onClick={() => chooseFilter(type, it.id)}
                    >
                      <Text fontSize={13}>{it.name}</Text>
                      <Spacer />
                      {it.isActive && <Icon as={BiCheck} textColor={'primary.default'} />}
                    </HStack>
                  );
                })}
        </SimpleGrid>
        {limit < data.data.length ? (
          <Box
            paddingX={4}
            paddingY={2}
            cursor={'pointer'}
            onClick={() => {
              setFilterMode({ type: type, isFull: true, data: data });
            }}
          >
            <Text fontWeight={'bold'} fontSize={13} textAlign={'center'}>
              Lihat Semua
            </Text>
          </Box>
        ) : (
          <Box paddingX={4} paddingY={2} />
        )}
      </Box>
    );
  };

  // Main
  return (
    <Fragment>
      <HStack
        width={'full'}
        paddingX={3}
        paddingBottom={3}
        paddingTop={1}
        borderBottomWidth={1}
        borderBottomColor={'light.main'}
      >
        <Spacer />
        <Tag
          colorScheme={'gray'}
          variant={'subtle'}
          bgColor={'white'}
          paddingY={2}
          border={'1px solid rgba(0,0,0,0.1)'}
          textColor={'light.dark'}
          cursor={'pointer'}
          onClick={onOpenSort}
        >
          <TagLeftIcon as={BiSortAlt2} fontSize={20}></TagLeftIcon>
          <TagLabel>{selectedSort !== undefined ? selectedSort.text : 'Urutkan'}</TagLabel>
        </Tag>
        <Tag
          colorScheme={'gray'}
          variant={'subtle'}
          paddingY={2}
          bgColor={'white'}
          border={'1px solid rgba(0,0,0,0.1)'}
          textColor={'light.dark'}
          cursor={'pointer'}
          onClick={onOpenFilter}
        >
          <TagLeftIcon as={BiFilter} fontSize={20}></TagLeftIcon>
          <TagLabel>Filter</TagLabel>
          {filterCount > 0 && (
            <Box marginLeft={2} paddingX={'7px'} paddingY={1} borderRadius={'full'} bgColor={'primary.default'}>
              <Text fontSize={10} fontWeight={'bold'} textColor={'white'}>
                {filterCount.toString()}
              </Text>
            </Box>
          )}
        </Tag>
      </HStack>
      {_showModalSort()}
      {_showModalFilter()}
    </Fragment>
  );
};

export default SearchFilter;
