import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

const ButtonGlobal: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      width={'full'}
      borderRadius="full"
      background="primary.default"
      _hover={{ background: 'primary.default', color: 'white' }}
      color="white"
      fontWeight={'bold'}
      {...props}
    />
  );
};

export default ButtonGlobal;
