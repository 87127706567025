import STORAGE from '@/constant/storage';

export const savePreCheckoutRequest = (payload: Order.PreCheckoutRequestProps) => ({
  type: STORAGE.PRE_CHECKOUT_REQUEST,
  payload,
});

export const removePreCheckoutRequest = () => ({
  type: STORAGE.PRE_CHECKOUT_REQUEST,
  payload: null,
});
