import React, { useId } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Image, ImageProps } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import useEventLog from '@/lib/hooks/utils/useEventLog';
import { useLayout } from '@/lib/hooks/utils';

export interface CarouselProps extends ImageProps {
  items: Home.BannerItem[];
  widthContainer?: number | string;
  heightContainer?: number | string;
  centered?: boolean;
  loop?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  items,
  width,
  height,
  widthContainer,
  heightContainer,
  centered = false,
  loop = false,
  ...rest
}): JSX.Element => {
  const id = useId();
  const router = useRouter();
  const { getParams } = useLayout();
  const { LOGS, writeLog } = useEventLog();

  const fromApps = getParams()?.from ?? '';

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView="auto"
      centeredSlides={centered}
      loop={loop}
      modules={[Autoplay]}
      autoplay={{
        delay: 10000,
      }}
    >
      {items?.map((item) => {
        const style: React.CSSProperties = { width: widthContainer || '60%', height: heightContainer || 'auto' };

        return (
          <SwiperSlide key={`banner-${id}-${item.id}`} style={style}>
            {item.sub_type === 'supplier' ? (
              <Image
                src={item.image_url}
                alt="caraousel-img"
                width={width}
                height={height}
                loading="lazy"
                placeholder={`https://via.placeholder.com/${width}x${height}`}
                fallbackSrc={`https://via.placeholder.com/${width}x${height}`}
                onClick={() => {
                  // Setup Analytics Click Supplier
                  writeLog(LOGS.CLICK.CLICK_SUPPLIER_FROM_BANNER_CAMPAIGN, item);

                  router.push(`supplier/detail?id=${item.sub_type_id}`);
                }}
                {...rest}
              />
            ) : (
              <Link href={item.content_url + `?title=${item.title}`} passHref>
                <a target={fromApps === 'apps' ? '_self' : '_blank'} rel="noopener noreferrer">
                  <Image
                    src={item.image_url}
                    alt="caraousel-img"
                    width={width}
                    height={height}
                    loading="lazy"
                    placeholder={`https://via.placeholder.com/${width}x${height}`}
                    fallbackSrc={`https://via.placeholder.com/${width}x${height}`}
                    {...rest}
                    onClick={() => {
                      // Setup Analytics View Page Banner
                      if (item.title?.includes('Referral Pro')) writeLog(LOGS.CLICK.CLICK_BANNER_REFERRAL_PRO, item);
                      else if (item.type.includes('video')) writeLog(LOGS.CLICK.CLICK_BANNER_TUTORIAL, item);
                      else writeLog(LOGS.CLICK.CLICK_BANNER_CAMPAIGN, item);
                    }}
                  />
                </a>
              </Link>
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;
