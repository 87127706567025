import React, { ChangeEvent } from 'react';
import { BottomSheet } from '@/components';
import { SimpleGrid } from '@chakra-ui/react';

import { Checkbox } from '@/components';

interface LocationsProps extends Home.ProductFilter {
  dataSelected: any[];
  onSelect: (value: ChangeEvent<HTMLInputElement>, section: 'Kategori') => void;
}

const Locations: React.FC<LocationsProps> = ({ product_filters, onSelect, dataSelected }): JSX.Element => {
  return (
    <BottomSheet.Body>
      <SimpleGrid columns={2} spacing={2} paddingX={1}>
        {product_filters
          .filter((item) => item.text === 'Kategori')
          .map((item) =>
            item.data
              .sort((a, b) => dataSelected.indexOf(b.id) - dataSelected.indexOf(a.id))
              .map((item) => (
                <Checkbox
                  key={item.id}
                  id={item.id.toString()}
                  label={item.name}
                  value={item.id}
                  defaultChecked={dataSelected.includes(item.id)}
                  onChange={(event) => onSelect(event, 'Kategori')}
                />
              )),
          )}
      </SimpleGrid>
    </BottomSheet.Body>
  );
};
export default Locations;
