import STORAGE from '@/constant/storage';

export const savePreCheckoutNew = (payload: any) => ({
  type: STORAGE.PRE_CHECKOUT_NEW,
  payload,
});

export const removePreCheckoutNew = () => ({
  type: STORAGE.PRE_CHECKOUT_NEW,
  payload: null,
});
