import axios, { AxiosRequestConfig } from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export * from '../myToko';
export const getResellerHistory = async () => {
  try {
    const url = '/v1/reseller/auth/my-data/history';
    const { data: response } = await fetcher.get(url);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
export const postResellerHistory = async (params: AxiosRequestConfig | Auth.postResellerHistory) => {
  try {
    const url = '/v1/reseller/auth/my-data/history';
    const { data: response } = await fetcher.post(url, params);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
export const getProfile = async () => {
  try {
    const url = API.RESELLER_PROFILE;
    const { data: response } = await fetcher.get(url);
    const data: Admin.MyData = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getCommission = async () => {
  try {
    const url = API.RESELLER_COMMISION;
    const { data: response } = await fetcher.get(url);
    const data: Admin.CommisionFinancial = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getRewardSummary = async () => {
  try {
    const url = API.RESELLER_REWARD_SUMMARY;
    const { data: response } = await fetcher.get(url);
    const data: Admin.RewardSummary = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getReward = async () => {
  try {
    const url = API.REWARD;
    const { data: response } = await fetcher.get(url);
    const data: Admin.Reward[] = response?.data?.rewards || [];
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getReferral = async () => {
  try {
    const url = API.RESELLER_REFERRAL('');
    const { data: response } = await fetcher.get(url);
    const data: Admin.ReferralDetail = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getKYCStatus = async () => {
  try {
    const url = API.KYC_STATUS;
    const { data: response } = await fetcher.get(url);
    const data: Admin.KYCStatus = response?.data?.kyc;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postKYC = async (formData: FormData) => {
  try {
    const url = API.KYC;
    const { data: response } = await fetcher.postForm(url, formData);
    const data: Admin.KYCStatus = response?.data?.kyc;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
