import { PayloadAction } from '@reduxjs/toolkit';
import { SAVE_TOKO_DATA } from './actionTypes';

type MyStoreInitialState = {
  toko: Admin.MyStore | undefined;
};

const initialState: MyStoreInitialState = {
  toko: undefined,
};
//
const TokoData = (state = initialState, action: PayloadAction<MyStoreInitialState>) => {
  switch (action.type) {
    case SAVE_TOKO_DATA:
      state = {
        ...state,
        toko: action.payload.toko,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default TokoData;
