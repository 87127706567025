import { ComponentSingleStyleConfig } from '@chakra-ui/theme';

const Drawer: ComponentSingleStyleConfig = {
  variants: {
    bottomSheet: {
      dialog: {
        maxWidth: 'md !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderTopRadius: 'xl',
      },
    },
  },
};

export default Drawer;
