import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface ProductDetailReducerProps {
  data: Product.ProductDetaiProps | null;
}

const initialState: ProductDetailReducerProps = {
  data: null,
};

const ProductDetailReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.PRODUCT_DETAIL:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default ProductDetailReducer;
