import { PayloadAction } from '@reduxjs/toolkit';
import { SAVE_PROFILE_DATA } from './types';

interface ProfileReducerProps {
  data: Admin.MyData;
}

const initialState: ProfileReducerProps = {
  data: {
    id: 0,
    email: '',
    phone: '',
    has_pin: false,
    is_phone_verified: false,
    is_whatsapp_verified: false,
    is_email_verified: false,
    created_at: '',
    current_tier: [],
    level: '',
    name: '',
    next_tier: [],
    password: '',
    photo: '',
    tiering_id: '',
    updated_at: '',
  },
};
//
const ReferralReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case SAVE_PROFILE_DATA:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default ReferralReducer;
