import { SAVE_DESCRIPTION, SAVE_DETAIL_PRODUCT_SHARE, SAVE_LIST_SHARE_IMAGE, SAVE_SELECTEDIMAGE } from './actionTypes';

export interface SaveShareImageProps {
  url: string;
  isSelected: boolean;
}

export const saveShareImage = (payload: SaveShareImageProps[]) => ({
  type: SAVE_LIST_SHARE_IMAGE,
  payload,
});

export const saveProductDescription = (payload: string) => ({
  type: SAVE_DESCRIPTION,
  payload,
});

export const saveSelectedImage = (payload: SaveShareImageProps[]) => ({
  type: SAVE_SELECTEDIMAGE,
  payload,
});

export const saveDetailProdcut = (payload: Product.ProductDetaiProps) => ({
  type: SAVE_DETAIL_PRODUCT_SHARE,
  payload,
});
