import { ImageShareProps } from '@/app/Share';
import { PayloadAction } from '@reduxjs/toolkit';
import { SAVE_DESCRIPTION, SAVE_DETAIL_PRODUCT_SHARE, SAVE_LIST_SHARE_IMAGE } from './actionTypes';

interface StateProps {
  imageProducts: ImageShareProps[];
  description: string;
  detailProductShare: Product.ProductDetaiProps | object;
}

const initialState: StateProps = {
  imageProducts: [],
  description: '',
  detailProductShare: {},
};
//
const ShareProductReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case SAVE_LIST_SHARE_IMAGE:
      state = {
        ...state,
        imageProducts: action.payload,
      };
      break;
    case SAVE_DESCRIPTION:
      state = {
        ...state,
        description: action.payload,
      };
      break;
    case SAVE_DETAIL_PRODUCT_SHARE:
      state = {
        ...state,
        detailProductShare: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default ShareProductReducer;
