import STORAGE from '@/constant/storage';

export const saveSearchProduct = (payload: string) => ({
  type: STORAGE.SEARCH_PRODUCT,
  payload,
});

export const removeSearchProduct = () => ({
  type: STORAGE.SEARCH_PRODUCT,
  payload: '',
});
