import API from './api';

const LIMIT_FAVORITE_LIST = 10;
export const TRANSFER_BANK = 'TRANSFER BANK';
export const COD = 'COD';
export const PAID = 'PAID';
export const QRIS = 'QRIS';
export const UNPAID = 'UNPAID';
export const IS_LOAD_LOG = true;

export * from './storage';

export { LIMIT_FAVORITE_LIST, API };
