import { ThemeConfig, ThemeDirection, extendTheme } from '@chakra-ui/react';
import { foundations } from '@/theme/foundations';
import { components } from '@/theme/components';
import { styles } from '@/theme/styles';

const direction: ThemeDirection = 'ltr';

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'ui',
};

export const theme = extendTheme({
  config,
  direction,
  ...foundations,
  components,
  styles,
});
