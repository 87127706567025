import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface SupplierCategoryDetailReducerProps {
  data: string;
}

const initialState: SupplierCategoryDetailReducerProps = {
  data: '',
};

const SupplierCategoryDetailReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.SUPPLIER_CATEGORY_DETAIL:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default SupplierCategoryDetailReducer;
