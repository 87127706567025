import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface SearchProductReducerProps {
  data: string;
}

const initialState: SearchProductReducerProps = {
  data: '',
};

const SearchProductReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.SEARCH_PRODUCT:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default SearchProductReducer;
