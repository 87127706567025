import STORAGE from '@/constant/storage';

export const saveProductDetail = (payload: Product.ProductDetaiProps) => ({
  type: STORAGE.PRODUCT_DETAIL,
  payload,
});

export const removeProductDetail = () => ({
  type: STORAGE.PRODUCT_DETAIL,
  payload: null,
});
