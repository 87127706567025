import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { fetcher } from '@/utils';
import { API } from '@/constant';
export const postVerifyOtpChangeEmailPhone = async (
  params: AxiosRequestConfig | Auth.VerifyOtp,
): Promise<Auth.VerifyOtpResponse> => {
  try {
    const { data: response } = await fetcher.post('/v1/reseller/auth/my-data/history/check_otp', params);
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
export const postRequestOtp = async (
  params: AxiosRequestConfig | Auth.RequestOtp,
): Promise<Auth.RequestOtpResponse> => {
  try {
    const { data: response } = await fetcher.post('/v1/reseller/auth/get_otp', params);
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const postVerifyOtp = async (params: AxiosRequestConfig | Auth.VerifyOtp): Promise<Auth.VerifyOtpResponse> => {
  try {
    const { data: response } = await fetcher.post('/v1/reseller/auth/verify_otp', params);
    const dateNow = new Date();
    const dateExpired = new Date(response?.data?.access_token_expire_in);
    const difference = dateExpired.getTime() - dateNow.getTime();
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

    Cookies.set('token', JSON.stringify(response.data), {
      expires: totalDays,
    });
    return { ...response, enableToast: true };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const postSetPin = async (pin: string) => {
  try {
    const url = API.RESELLER_PIN;
    const { data: response } = await fetcher.post(url, {
      pin: pin,
    });
    const data: {
      status: boolean;
    } = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postVerifyOtpWithdraw = async (
  params: AxiosRequestConfig | Auth.VerifyOtp,
): Promise<Auth.VerifyOtpResponse> => {
  try {
    const { data: response } = await fetcher.post('/v1/payment/withdraw/check_otp', params);
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const postValidatePIN = async (pin: string) => {
  try {
    const url = API.WITHDRAW_VALIDATE_PIN;
    const { data: response } = await fetcher.post(url, {
      pin: pin,
    });
    const data: boolean = response?.success || false;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postGoogleLogin = async (body: Auth.GoogleLoginProps) => {
  try {
    const url = '/v1/reseller/auth/google';
    const { data: response } = await fetcher.post(url, body);
    const dateNow = new Date();
    const dateExpired = new Date(response?.data?.access_token_expire_in);
    const difference = dateExpired.getTime() - dateNow.getTime();
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

    Cookies.set('token', JSON.stringify(response.data), {
      expires: totalDays,
    });
    return { ...response, enableToast: true };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
