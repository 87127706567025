import STORAGE from '@/constant/storage';
import { PayloadAction } from '@reduxjs/toolkit';

interface CategoryDetailReducerProps {
  data: Portfolio.SubSubPortfoliosProps | null;
}

const initialState: CategoryDetailReducerProps = {
  data: null,
};

const CategoryDetailReducer = (state = initialState, action: PayloadAction<any>) => {
  switch (action.type) {
    case STORAGE.CATEGORY_DETAIL:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state;
      break;
  }
  return state;
};

export default CategoryDetailReducer;
