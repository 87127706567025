import axios from 'axios';
import { fetcher } from '@/utils';
import { API } from '@/constant';

export const getCartCount = async () => {
  try {
    const url = API.CART_COUNT;
    const { data: response } = await fetcher.get(url);
    const data: Cart.CartCountProps = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getCart = async () => {
  try {
    const url = API.CART;
    const { data: response } = await fetcher.get(url);
    const data: Cart.CartItemProps[] = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postPotentialReward = async (request: Cart.PotentialRewardReq) => {
  try {
    const url = API.POTENTIAL_REWARD;
    const { data: response } = await fetcher.post(url, request);
    const data: Cart.PotentialReward = response?.data;
    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postAddToCart = async (request: Cart.AddToCartRequest) => {
  try {
    const url = API.CART;
    const { data: response } = await fetcher.post(url, request);
    const data: Cart.AddToCartResponse = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const deleteFromCart = async (itemID: string) => {
  try {
    const url = API.CART_DELETE(itemID);
    const { data: response } = await fetcher.delete(url);
    const data: Cart.DeleteCartResponse = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const putChangeCart = async (request: Cart.UpdateCartRequest) => {
  try {
    const url = API.CART;
    const { data: response } = await fetcher.put(url, request);
    const data: Cart.UpdateCartResponse = response?.data;
    return { data: data, message_client: response?.message_client || '' };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postPreCheckout2 = async (payload: any) => {
  try {
    const url = API.SEND_PRE_CHECKOUT();
    const { data: response } = await fetcher.post(url, payload);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
    throw e;
  }
};
