const useStorage = () => {
  const saveToStorage = async (key: string, value: any) => {
    value = JSON.stringify(value);
    window.localStorage.setItem(key, value);
  };

  const getFromStorage = async (key: string) => {
    const data = await window.localStorage.getItem(key);
    if (data !== undefined && data !== null && (data?.length || 0) > 0) {
      return JSON.parse(data || '');
    }
    return null;
  };

  const removeFromStorage = async (key: string) => {
    window.localStorage.removeItem(key);
  };

  return {
    saveToStorage,
    getFromStorage,
    removeFromStorage,
  };
};

export default useStorage;
