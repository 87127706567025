import React, { ChangeEvent } from 'react';
import { BottomSheet } from '@/components';
import { SimpleGrid } from '@chakra-ui/react';

import { Checkbox } from '@/components';

interface LocationsProps extends Home.ProductFilter {
  isJabodetabek?: boolean;
  dataSelected: any[];
  onSelect: (value: ChangeEvent<HTMLInputElement>, section: 'Lokasi Supplier') => void;
}

const Locations: React.FC<LocationsProps> = ({
  product_filters,
  onSelect,
  dataSelected,
  isJabodetabek,
}): JSX.Element => {
  return (
    <BottomSheet.Body>
      <SimpleGrid columns={2} spacing={2} paddingX={1}>
        {product_filters
          .filter((item) => item.text === 'Lokasi Supplier')
          .map((item) =>
            item.data
              .sort((a, b) => dataSelected.indexOf(b.id) - dataSelected.indexOf(a.id))
              .map((item) => (
                <Checkbox
                  key={item.id}
                  id={item.id.toString()}
                  label={item.name}
                  value={item.id}
                  defaultChecked={dataSelected.includes(item.id) || (item.id === 0 && isJabodetabek) ? true : false}
                  onChange={(event) => onSelect(event, 'Lokasi Supplier')}
                />
              )),
          )}
      </SimpleGrid>
    </BottomSheet.Body>
  );
};
export default Locations;
