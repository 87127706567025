import axios from 'axios';
import { fetcher, handleParamUrl } from '@/utils';

export const getUserData = async () => {
  try {
    const url = '/v1/reseller/auth/my-data';
    const { data: response } = await fetcher.get(url);
    return { ...response, enableToast: false };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const postInputWa = async (data: Auth.RequestOtp) => {
  try {
    const url = '/v1/reseller/auth/get_otp';
    const { data: response } = await fetcher.post(url, data);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const verifyWaApi = async (data: Auth.VerifyOtp) => {
  try {
    const url = '/v1/reseller/auth/verify_whatsapp';
    const { data: response } = await fetcher.post(url, data);
    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const getMyFavoriteApi = async (param: Favorite.RequestListFavProps) => {
  try {
    let url = '/v1/product/list';
    url = handleParamUrl(url, param);
    const { data: response } = await fetcher.get(url);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const deleteFavProduct = async (id?: number) => {
  try {
    const url = `/v1/product/dropship/${id}`;
    const { data: response } = await fetcher.delete(url);
    return { ...response, enableToast: true };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const detailFavoriteApi = async (id?: number | string) => {
  try {
    const url = `/v1/product/${id}/toko`;
    const { data: response }: Favorite.ResponseFavDetail = await fetcher.get(url);
    return { ...response, enableToast: false };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};

export const submitEditFavorite = async (data: Favorite.BodyEditFavProduct) => {
  try {
    const url = '/v1/product/dropship';
    const { data: response } = await fetcher.put(url, data);
    return { ...response, enableToast: true };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e.response?.data;
    }
  }
};
