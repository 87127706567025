import React from 'react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { GrLocation } from 'react-icons/gr';
import { currencyFormatter } from '@/utils';
import { useAppDispatch } from '@/store';
import { removeProductDetail } from '@/store/product_detail/action';
import { BsBoxSeam } from 'react-icons/bs';

interface CardProductProps {
  width?: string;
  index: number;
  name: string;
  image: string;
  price: number;
  city: string;
  product_sold_alias: string;
  stock?: number;
  status: number;
  is_bundling?: boolean;
  onItemClick: (index: number) => void;
}
const CardProduct: React.FC<CardProductProps> = ({
  width = 'full',
  index,
  name,
  image,
  price,
  city,
  product_sold_alias,
  status,
  is_bundling,
  onItemClick,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Box
      w={width}
      shadow="md"
      borderWidth="1px"
      borderRadius="md"
      cursor="pointer"
      display="flex"
      flexDirection="column"
      position={'relative'}
      onClick={() => {
        dispatch(removeProductDetail());
        onItemClick(index);
      }}
    >
      <Box>
        <Box
          borderTopStartRadius={4}
          borderTopEndRadius={4}
          maxWidth="100%"
          height={'180px'}
          backgroundSize={'cover'}
          backgroundRepeat={'no-repeat'}
          backgroundImage={`url('${image}')`}
          marginBottom={2}
        />
        {is_bundling && (
          <Flex paddingX={2} alignItems={'center'} gap={1}>
            <BsBoxSeam size={'10px'} />
            <Text fontSize={'10px'}>Produk</Text>
          </Flex>
        )}
        <Text paddingX={2} fontSize={13} noOfLines={2}>
          {name}
        </Text>
        <Text color={'primary.default'} paddingX={2} paddingBottom={1} fontSize={15} fontWeight={'bold'}>
          {currencyFormatter(price)}
        </Text>
        {/* <Text paddingX={2} fontSize={10} marginBottom={2} noOfLines={2}>
          Terjual {product_sold_alias}
        </Text> */}
      </Box>
      <Box marginTop="auto" display="flex" marginBottom="2" paddingTop={3}>
        <Box display="flex" marginLeft="auto" marginRight="2" marginBottom={0.5}>
          {/* <Icon as={GrLocation} /> */}
          <Text fontSize="xs" marginLeft={1.5}>
            {city}
          </Text>
        </Box>
      </Box>
      {status > 0 && (
        <Box
          position={'absolute'}
          left={0}
          right={0}
          top={0}
          bottom={0}
          bgColor={'rgba(0,0,0,0.5)'}
          borderRadius={'md'}
        >
          <Box minHeight={'180px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Box borderWidth={'2px'} borderColor={'white'} textColor="white" marginLeft={4} marginRight={4} padding={2}>
              <Text textAlign={'center'} fontSize={14} fontWeight={'semibold'}>
                {status === 1 ? 'TOKO TIDAK AKTIF' : status === 2 ? 'PRODUK TIDAK AKTIF' : 'STOK HABIS'}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardProduct;
