import { RootState } from '@/store';
import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import useStorage from './useStorage';
import { IS_LOAD_LOG } from '@/constant';

const useEventLog = () => {
  const profile = useSelector((state: RootState) => state.UserData && state.UserData.user) as Admin.MyData;
  const { getFromStorage, saveToStorage } = useStorage();

  const LOGS = {
    PAGE: {
      // Login
      PAGE_LOGIN_WEB: 'page_login_web',
      // Homapage
      PAGE_MARKETPLACE_WEB: 'page_marketplace_web',
      PAGE_CATEGORY_WEB: 'page_category_web',
      PAGE_FAVORITE_WEB: 'page_favorite_web',
      PAGE_ORDER_WEB: 'page_order_web',
      PAGE_ADMIN_WEB: 'page_admin_web',
      // Product
      PAGE_PRODUCT_DETAIL_WEB: 'page_product_detail_web',
      PAGE_PRODUCT_DETAIL_SHARE_NO_EDIT_WEB: 'page_product_detail_share_no_edit_web',
      PAGE_PRODUCT_DETAIL_SHARE_EDIT_WEB: 'page_product_detail_share_edit_web',
      PAGE_PRODUCT_DETAIL_SHARE_EDIT_TEMPLATE_WEB: 'page_product_detail_share_edit_template_web',
      PAGE_PRODUCT_DETAIL_SHARE_EDIT_TEMPLATE_EDIT_WEB: 'page_product_detail_share_edit_template_edit_web',
      // Search
      PAGE_SEARCH_WEB: 'page_search_web',
      // Cart
      PAGE_CART_WEB: 'page_cart_web',
      // Supplier
      PAGE_SUPPLIER_DETAIL_WEB: 'page_supplier_detail_web',
      PAGE_SUPPLIER_CATEGORY_DETAIL_WEB: 'page_supplier_category_detail_web',
      // Category
      PAGE_CATEGORY_DETAIL_WEB: 'page_category_detail_web',
      // Address
      PAGE_ADDRESS_LIST_WEB: 'page_address_list_web',
      PAGE_ADDRESS_CREATE_WEB: 'page_address_create_web',
      PAGE_ADDRESS_EDIT_WEB: 'page_address_edit_web',
      PAGE_ADDRESS_CHECKOUT_WEB: 'page_address_checkout_web',
      PAGE_ADDRESS_LOCATION_WEB: 'page_address_location_web',
      // Voucher
      PAGE_VOUCHER_MINE_WEB: 'page_voucher_mine_web',
      PAGE_VOUCHER_DETAIL_WEB: 'page_voucher_detail_web',
      PAGE_VOUCHER_CHECKOUT_WEB: 'page_voucher_checkout_web',
      // Checkout
      PAGE_PREVIEW_CHECKOUT_WEB: 'page_preview_checkout_web',
      PAGE_PAYMENT_WEBVIEW_WEB: 'page_payment_webview_web',
      // Order
      PAGE_ORDER_DETAIL_WEB: 'page_order_detail_web',
      PAGE_ORDER_TRANSACTION_WEB: 'page_order_transaction_web',
      PAGE_ORDER_COMPLAINT_CREATE_WEB: 'page_order_complaint_create_web',
      PAGE_ORDER_COMPLAINT_DETAIL_WEB: 'page_order_complaint_detail_web',
      // FAVORITE
      PAGE_FAVORITE_PRODUCT_EDIT_WEB: 'page_favorite_product_edit_web',
      // KYC
      PAGE_KYC_WEB: 'page_kyc_web',
      // Finance
      PAGE_FINANCE_WEB: 'page_finance_web',
      PAGE_RERERRAL_WEB: 'page_referral_web',
      PAGE_HOLD_SALDO_WEB: 'page_hold_saldo_web',
      PAGE_WITHDRAW_WEB: 'page_withdraw_web',
      PAGE_WITHDRAW_HISTORY_WEB: 'page_withdraw_history_web',
      PAGE_REFERRAL_ABOUT_WEB: 'page_referral_about_web',
      PAGE_REFERRAL_SHARE_WEB: 'page_referral_share_web',
      PAGE_REFERRAL_MINE_WEB: 'page_referral_mine_web',
      // Reward
      PAGE_REWARD_JOURNEY_WEB: 'page_reward_journey_web',
      PAGE_REWARD_DETAIL_WEB: 'page_reward_detail_web',
      PAGE_REWARD_CLAIM_HISTORY_WEB: 'page_reward_claim_history_web',
      // Store
      PAGE_STORE_MINE_WEB: 'page_store_mine_web',
      // Edit Profile
      PAGE_EDIT_PROFILE_WEB: 'page_edit_profile_web',
      PAGE_EDIT_PROFILE_FORM_WEB: 'page_edit_profile_form_web',
      // Pin
      PAGE_PIN_OTP_WEB: 'page_pin_otp_web',
      PAGE_PIN_NEW_WEB: 'page_pin_new_web',
      PAGE_PIN_REPEAT_WEB: 'page_pin_repeat_web',
      PAGE_PIN_SUCCESS_WEB: 'page_pin_success_web',
    },
    CLICK: {
      // Tab Product Marketplace
      CLICK_TAB_PRODUCT_MARKETPLACE: 'click_tab_product_marketplace_web',
      // Filter Product
      CLICK_FILTER_PRODUCT: 'click_filter_product_web',
      // Product From
      CLICK_PRODUCT_FROM_RECOMMENDATION: 'click_product_from_recommendation_web',
      CLICK_PRODUCT_FROM_BEST_SELLER: 'click_product_from_best_seller_web',
      CLICK_PRODUCT_FROM_NEWEST: 'click_product_from_newest_web',
      CLICK_PRODUCT_FROM_TRENDING: 'click_product_from_trending_web',
      CLICK_PRODUCT_FROM_FAVORITE: 'click_product_from_favorite_web',
      CLICK_PRODUCT_FROM_ORDER_DETAIL: 'click_product_from_order_detail_web',
      CLICK_PRODUCT_FROM_CART: 'click_product_from_cart_web',
      CLICK_PRODUCT_FROM_SEARCH: 'click_product_from_search_web',
      CLICK_PRODUCT_FROM_CATEGORY_DETAIL: 'click_product_from_category_detail_web',
      CLICK_PRODUCT_FROM_SUPPLIER_DETAIL: 'click_product_from_supplier_detail_web',
      CLICK_PRODUCT_FROM_SUPPLIER_CATEGORY_DETAIL: 'click_product_from_supplier_category_detail_web',
      // Banner
      CLICK_BANNER_CAMPAIGN: 'click_banner_campaign_web',
      CLICK_BANNER_TUTORIAL: 'click_banner_tutorial_web',
      CLICK_BANNER_REFERRAL_PRO: 'click_banner_referral_pro_web',
      // Cart Count
      CLICK_CART_COUNT_FROM_MARKETPLACE: 'click_cart_count_from_marketplace_web',
      CLICK_CART_COUNT_FROM_CATEGORY: 'click_cart_count_from_category_web',
      CLICK_CART_COUNT_FROM_PRODUCT_DETAIL: 'click_cart_count_from_product_detail_web',
      CLICK_CART_COUNT_FROM_SUPPLIER_DETAIL: 'click_cart_count_from_supplier_detail_web',
      // Supplier
      CLICK_SUPPLIER_FROM_BANNER_CAMPAIGN: 'click_supplier_from_banner_campaign_web',
      CLICK_SUPPLIER_FROM_PRODUCT_DETAIL: 'click_supplier_from_product_detail_web',
      CLICK_SUPPLIER_FROM_SEARCH: 'click_supplier_from_search_web',
      CLICK_SUPPLIER_FROM_CART: 'click_supplier_from_cart_web',
      // Category
      CLICK_CATEGORY_TAB: 'click_category_tab_web',
      CLICK_CATEGORY_ITEM: 'click_category_item_web',
      // Supplier
      CLICK_SUPPLIER_PRODUCT_TAB: 'click_supplier_product_tab_web',
      CLICK_SUPPLIER_CATEGORY_TAB: 'click_supplier_category_tab_web',
      CLICK_SUPPLIER_CATEGORY_ITEM: 'click_supplier_category_item_web',
      // PRODUCT DETAIL
      CLICK_PRODUCT_DETAIL_IMAGE: 'click_product_detail_image_web',
      CLICK_PRODUCT_DETAIL_DOWNLOAD_IMAGE: 'click_product_detail_download_image_web',
      CLICK_PRODUCT_DETAIL_COPY_PRODUCT_NAME: 'click_product_detail_copy_product_name_web',
      CLICK_PRODUCT_DETAIL_COPY_PRODUCT_ID: 'click_product_detail_copy_product_id_web',
      CLICK_PRODUCT_DETAIL_COPY_DESCRIPTION: 'click_product_detail_copy_description_web',
      CLICK_PRODUCT_DETAIL_COPY_NOTES: 'click_product_detail_copy_notes_web',
      CLICK_PRODUCT_DETAIL_COPY_DESCRIPTION_AND_NOTES: 'click_product_detail_copy_description_and_notes_web',
      CLICK_PRODUCT_DETAIL_ADD_TO_FAVORITE: 'click_product_detail_add_to_favorite_web',
      CLICK_PRODUCT_DETAIL_DELETE_FROM_FAVORITE: 'click_product_detail_delete_from_favorite_web',
      CLICK_PRODUCT_DETAIL_MARKUP_TYPE_PERCENTAGE: 'click_product_detail_markup_type_percentage_web',
      CLICK_PRODUCT_DETAIL_MARKUP_TYPE_RUPIAH: 'click_product_detail_markup_type_rupiah_web',
      CLICK_PRODUCT_DETAIL_MARKUP_DETAIL: 'click_product_detail_markup_detail',
      CLICK_PRODUCT_DETAIL_SHARE_NO_EDIT: 'click_product_detail_share_no_edit_web',
      CLICK_PRODUCT_DETAIL_SHARE_EDIT: 'click_product_detail_share_edit_web',
      CLICK_PRODUCT_DETAIL_ADD_TO_CART: 'click_product_detail_add_to_cart_web',
      CLICK_PRODUCT_DETAIL_VARIANT_1: 'click_product_detail_variant_1_web',
      CLICK_PRODUCT_DETAIL_VARIANT_2: 'click_product_detail_variant_2_web',
      CLICK_PRODUCT_DETAIL_VARIANT_3: 'click_product_detail_variant_3_web',
      CLICK_PRODUCT_DETAIL_VARIANT_4: 'click_product_detail_variant_4_web',
      CLICK_PRODUCT_DETAIL_SHIPPING_PRICE: 'click_product_detail_shipping_price_web',
      CLICK_PRODUCT_DETAIL_SHIPPING_LOCATION: 'click_product_detail_shipping_location_web',
      // Share Product
      CLICK_SHARE_PRODUCT_NO_EDIT_CHOOSE_ALL: 'click_share_product_no_edit_choose_all_web',
      CLICK_SHARE_PRODUCT_NO_EDIT_CHOOSE_SPECIFIC: 'click_share_product_no_edit_choose_specific_web',
      CLICK_SHARE_PRODUCT_NO_EDIT_CHOOSE_PHOTO_NEXT: 'click_share_product_no_edit_choose_photo_next_web',
      CLICK_SHARE_PRODUCT_NO_EDIT_COPY_DESCRIPTION: 'click_share_product_no_edit_copy_description_web',
      CLICK_SHARE_PRODUCT_NO_EDIT_BY: 'click_share_product_no_edit_by_web',
      CLICK_SHARE_PRODUCT_EDIT_CHOOSE_PHOTO: 'click_share_product_edit_choose_photo_web',
      CLICK_SHARE_PRODUCT_EDIT_CHOOSE_PHOTO_NEXT: 'click_share_product_edit_choose_photo_next_web',
      CLICK_SHARE_PRODUCT_EDIT_CHOOSE_TEMPLATE: 'click_share_product_edit_choose_template_web',
      CLICK_SHARE_PRODUCT_EDIT_CHOOSE_TEMPLATE_NEXT: 'click_share_product_edit_choose_template_next_web',
      CLICK_SHARE_PRODUCT_EDIT_COPY_DESCRIPTION: 'click_share_product_edit_copy_description_web',
      CLICK_SHARE_PRODUCT_EDIT_BY: 'click_share_product_edit_choose_by_web',
      // Search
      CLICK_SEARCH_AUTOCOMPLETE: 'click_search_autocomplete_web',
      CLICK_SEARCH_HINT: 'click_search_hint_web',
      // Favorite
      CLICK_FAVORITE_OPTION: 'click_favorite_option_web',
      CLICK_FAVORITE_OPTION_SHARE: 'click_favorite_option_share_web',
      CLICK_FAVORITE_OPTION_EDIT: 'click_favorite_option_edit_web',
      CLICK_FAVORITE_OPTION_DELETE: 'click_favorite_option_delete_web',
      CLICK_FAVORITE_EDIT_MARKUP_TYPE_PERCENTAGE: 'click_favorite_edit_markup_type_percentage_web',
      CLICK_FAVORITE_EDIT_MARKUP_TYPE_RUPIAH: 'click_favorite_edit_markup_type_rupiah_web',
      CLICK_FAVORITE_DELETE: 'click_favorite_delete_web',
      CLICK_VERIFY_WHATSAPP: 'click_verify_whatsapp_web',
      CLICK_FAVORITE_EDIT: 'click_favorite_edit_web',
      // ORDER
      CLICK_ORDER_STATUS: 'click_order_status_web',
      CLICK_ORDER_DETAIL: 'click_order_detail_web',
      CLICK_ORDER_DETAIL_FROM_TRANSACTION: 'click_order_detail_from_transaction_web',
      CLICK_ORDER_DETAIL_FROM_FINANCE: 'click_order_detail_from_finance_web',
      CLICK_ORDER_DETAIL_TRANSACTION: 'click_order_detail_transaction_web',
      CLICK_ORDER_DETAIL_TRANSACTION_PAYMENT: 'click_order_detail_transaction_payment_web',
      CLICK_ORDER_DETAIL_CANCEL: 'click_order_detail_cancel_web',
      CLICK_ORDER_DETAIL_COMPLAINT: 'click_order_detail_complaint_web',
      CLICK_ORDER_DETAIL_COPY_INVOICE: 'click_order_detail_copy_invoice_web',
      CLICK_ORDER_DETAIL_TRACKING: 'click_order_detail_tracking_web',
      CLICK_ORDER_DETAIL_TRANSACTION_DOWNLOAD: 'click_order_detail_transaction_download_web',
      CLICK_ORDER_DETAIL_TRANSACTION_COPY_INVOICE: 'click_order_detail_transaction_copy_invoice_web',
      CLICK_ORDER_DETAIL_TRANSACTION_WA: 'click_order_detail_transaction_wa_web',
      CLICK_ORDER_DETAIL_TRANSACTION_CALL: 'click_order_detail_transaction_call_web',
      // Admin
      CLICK_ADMIN_KYC: 'click_admin_kyc_web',
      CLICK_ADMIN_FINANCE: 'click_admin_finance_web',
      CLICK_ADMIN_HOLD_SALDO: 'click_admin_hold_saldo_web',
      CLICK_ADMIN_REWARD_ABOUT: 'click_admin_reward_about_web',
      CLICK_ADMIN_REWARD: 'click_admin_reward_web',
      CLICK_ADMIN_REFERRAL_MINE: 'click_admin_referral_mine_web',
      CLICK_ADMIN_STORE_MINE: 'click_admin_store_mine_web',
      CLICK_ADMIN_VOUCHER: 'click_admin_voucher_web',
      CLICK_ADMIN_ADDRESS: 'click_admin_address_web',
      CLICK_ADMIN_REFUND: 'click_admin_refund_web',
      CLICK_ADMIN_EDIT_KYC: 'click_admin_edit_kyc_web',
      CLICK_ADMIN_EDIT_PROFILE: 'click_admin_edit_profile_web',
      CLICK_ADMIN_PIN: 'click_admin_pin_web',
      CLICK_ADMIN_TUTORIAL: 'click_admin_tutorial_web',
      CLICK_ADMIN_HOW_TO: 'click_admin_how_to_web',
      CLICK_ADMIN_CALL_US: 'click_admin_call_us_web',
      CLICK_ADMIN_CONTACT_US: 'click_admin_contact_us_web',
      CLICK_ADMIN_TERM_CONDITION: 'click_admin_term_condition_web',
      CLICK_ADMIN_PRIVACY_POLICY: 'click_admin_privacy_policy_web',
      CLICK_ADMIN_FEEDBACK: 'click_admin_feedback_web',
      CLICK_ADMIN_REQUEST_FITUR: 'click_admin_request_fitur_web',
      CLICK_ADMIN_CLEAR_CACHE: 'click_admin_clear_cache_web',
      CLICK_ADMIN_LOGOUT: 'click_admin_logout_web',
      // Login
      CLICK_LOGIN_WITH_GOOGLE: 'click_login_with_google_web',
      CLICK_LOGIN_WITH_EMAIL: 'click_login_with_email_web',
      CLICK_LOGIN_WITH_EMAIL_OTP: 'click_login_with_email_otp_web',
      CLICK_LOGIN_CONTACT_US: 'click_login_contact_us_web',
      CLICK_KYC_CONTACT_US: 'click_kyc_contact_us_web',
      // Cart
      CLICK_CART_DELETE_ITEM: 'click_cart_delete_item_web',
      CLICK_CART_SELECT_ALL: 'click_cart_select_all_web',
      CLICK_CART_SELECT_SUPPLIER: 'click_cart_select_supplier_web',
      CLICK_CART_SELECT_ITEM: 'click_cart_select_item_web',
      CLICK_CART_CHECKOUT: 'click_cart_checkout_web',
      // Address
      CLICK_ADDRESS_CHECKOUT_PICK: 'click_address_checkout_pick_web',
      CLICK_ADDRESS_CHECKOUT_LOCATION: 'click_address_checkout_location_web',
      CLICK_ADDRESS_CHECKOUT_FORM: 'click_address_checkout_form_web',
      CLICK_ADDRESS_CHECKOUT_SAVE_ADDRESS: 'click_address_checkout_save_web',
      CLICK_ADDRESS_LIST_PICK: 'click_address_list_pick_web',
      CLICK_ADDRESS_LIST_OPTION: 'click_address_list_option_web',
      CLICK_ADDRESS_LIST_OPTION_EDIT: 'click_address_list_option_edit_web',
      CLICK_ADDRESS_LIST_OPTION_COPY: 'click_address_list_option_copy_web',
      CLICK_ADDRESS_LIST_OPTION_DELETE: 'click_address_list_option_delete_web',
      CLICK_ADDRESS_LIST_CREATE: 'click_address_list_create_web',
      CLICK_ADDRESS_CREATE_FORM: 'click_address_create_form_web',
      CLICK_ADDRESS_CREATE_LOCATION: 'click_address_create_location_web',
      CLICK_ADDRESS_CREATE_SAVE: 'click_address_create_save_web',
      CLICK_ADDRESS_EDIT_LOCATION: 'click_address_edit_location_web',
      CLICK_ADDRESS_EDIT_SAVE: 'click_address_edit_save_web',
      CLICK_ADDRESS_EDIT_FORM: 'click_address_edit_form_web',
      CLICK_ADDRESS_DELETE: 'click_address_delete_web',
      CLICK_ADDRESS_LOCATION_PROVINCE: 'click_address_location_province_web',
      CLICK_ADDRESS_LOCATION_CITY: 'click_address_location_city_web',
      CLICK_ADDRESS_LOCATION_DISTRICT: 'click_address_location_district_web',
      CLICK_ADDRESS_LOCATION_POSTAL_CODE: 'click_address_location_postal_code_web',
      CLICK_ADDRESS_LOCATION_SUBMIT: 'click_address_location_submit_web',
      CLICK_ADDRESS_LOCATION_RESET: 'click_address_location_reset_web',
      // Preview Checkout
      CLICK_PREVIEW_CHECKOUT_PAYMENT_OPTION: 'click_preview_checkout_payment_option_web',
      CLICK_PREVIEW_CHECKOUT_PICK_SHIPPING: 'click_preview_checkout_pick_shipping_web',
      CLICK_PREVIEW_CHECKOUT_VOUCHER_ORDER: 'click_preview_checkout_voucher_order_web',
      CLICK_PREVIEW_CHECKOUT_VOUCHER_TRANSACTION: 'click_preview_checkout_voucher_transaction_web',
      CLICK_PREVIEW_CHECKOUT_KYC: 'click_preview_checkout_kyc_web',
      CLICK_PREVIEW_CHECKOUT_CONTACT_US: 'click_preview_checkout_contact_us_web',
      CLICK_CHECKOUT: 'click_checkout_web',
      // Voucher
      CLICK_VOUCHER_CHECKOUT_ORDER: 'click_voucher_checkout_order_web',
      CLICK_VOUCHER_CHECKOUT_TRANSACTION: 'click_voucher_checkout_transaction_web',
      CLICK_VOUCHER_TAB: 'click_voucher_tab_web',
      CLICK_VOUCHER_ITEM: 'click_voucher_item_web',
      // KYC
      CLICK_KYC_PHOTO_IDENTITY: 'click_kyc_photo_identity_web',
      CLICK_KYC_PHOTO_SWA: 'click_kyc_photo_swafoto_web',
      CLICK_KYC_SAVE: 'click_kyc_save_web',
      // Finance
      CLICK_FINANCE_STATUS: 'click_finance_status_web',
      CLICK_FINANCE_COPY_STORE_LINK: 'click_finance_copy_store_link_web',
      CLICK_FINANCE_SHARE_STORE_LINK: 'click_finance_share_store_link_web',
      CLICK_WITHDRAW_HISTORY: 'click_withdraw_history_web',
      CLICK_WITHDRAW: 'click_withdraw_web',
      CLICK_WITHDRAW_KYC: 'click_withdraw_kyc_web',
      CLICK_WITHDRAW_CONTACT_US: 'click_withdraw_contact_us_web',
      CLICK_WITHDRAW_CHOOSE_ALL_COMMISION: 'click_withdraw_choose_all_commision_web',
      CLICK_WITHDRAW_CHOOSE_ALL_REFERRAL: 'click_withdraw_choose_all_referral_web',
      CLICK_WITHDRAW_CHOOSE_DEFAULT_BANK_ACCOUNT: 'click_withdraw_choose_default_bank_account_web',
      CLICK_WITHDRAW_NEW_BANK_ACCOUNT: 'click_withdraw_new_bank_account_web',
      CLICK_WITHDRAW_CHOOSE_BANK: 'click_withdraw_choose_bank_web',
      CLICK_WITHDRAW_NEW_BANK_ACCOUNT_SUBMIT: 'click_withdraw_new_bank_account_submit_web',
      CLICK_WITHDRAW_DETAIL: 'click_withdraw_detail_web',
      CLICK_WITHDRAW_SUBMIT: 'click_withdraw_submit_web',
      // Hold Saldo
      CLICK_HOLD_SALDO_STATUS: 'click_hold_saldo_status_web',
      CLICK_HOLD_SALDO_CONTACT_US: 'click_hold_saldo_contact_us_web',
      // REWARD
      CLICK_REWARD_HISTORY: 'click_reward_history_web',
      CLICK_REWARD_ITEM: 'click_reward_item_web',
      CLICK_REWARD_CLAIM: 'click_reward_claim_web',
      // REFERRAL
      CLICK_REFERRAL_COPY_CODE: 'click_referral_copy_code_web',
      CLICK_REFERRAL_COPY_LINK: 'click_referral_copy_link_web',
      CLICK_REFERRAL_SHARE: 'click_referral_share_web',
      CLICK_REFERRAL_SHARE_LINK: 'click_referral_share_link_web',
      CLICK_REFERRAL_SHARE_DOWNLOAD: 'click_referral_share_download_web',
      CLICK_REFERRAL_SHARE_COPY_CONTENT: 'click_referral_share_copy_content_web',
      CLICK_REFERRAL_MENTOR: 'click_referral_mentor_web',
      // PIN
      CLICK_PIN_RE_REQUEST_OTP: 'click_pink_re_request_otp_web',
      CLICK_PIN_SUBMIT_OTP: 'click_pink_submit_web',
      // Store
      CLICK_STORE_TO_FAVORITE: 'click_store_to_favorite_web',
      CLICK_STORE_COPY_LINK: 'click_store_copy_link_web',
      CLICK_STORE_COPY_NAME: 'click_store_copy_name_web',
      CLICK_STORE_SHARE: 'click_store_share_web',
      CLICK_STORE_LOGO_FROM_CAMERA: 'click_store_logo_from_camera_web',
      CLICK_STORE_LOGO_FROM_GALLERY: 'click_store_logo_from_gallery_web',
      CLICK_STORE_CHANGE_NAME: 'click_store_change_name_web',
      CLICK_STORE_EDIT_ADDRESS: 'click_store_edit_address_web',
      CLICK_STORE_EDIT_ADDRESS_SAVE: 'click_store_edit_address_save_web',
      CLICK_STORE_EDIT_ADDRESS_PROVINCE: 'click_store_edit_address_province_web',
      CLICK_STORE_EDIT_ADDRESS_CITY: 'click_store_edit_address_city_web',
      CLICK_STORE_EDIT_ADDRESS_DISTRICT: 'click_store_edit_address_district_web',
      CLICK_STORE_EDIT_ADDRESS_POSTAL_CODE: 'click_store_edit_address_postal_code_web',
      CLICK_STORE_EDIT_ADDRESS_RESET: 'click_store_edit_address_reset_web',
      CLICK_STORE_FB_PIXEL: 'click_store_fb_pixel_web',
      CLICK_STORE_FB_PIXEL_SAVE: 'click_store_fb_pixel_save_web',
      CLICK_STORE_ECOMMERCE: 'click_store_ecommerce_web',
      CLICK_STORE_ECOMMERCE_SAVE: 'click_store_ecommerce_save_web',
      CLICK_STORE_SOCIAL_MEDIA: 'click_store_social_media_web',
      CLICK_STORE_SOCIAL_MEDIA_SAVE: 'click_store_social_media_save_web',
      // Profile
      CLICK_EDIT_PROFILE_EMAIL: 'click_edit_profile_email_web',
      CLICK_EDIT_PROFILE_PHONE: 'click_edit_profile_phone_web',
      CLICK_EDIT_PROFILE_CONFIRM: 'click_edit_profile_confirm_web',
      CLICK_EDIT_PROFILE_RESET: 'click_edit_profile_reset_web',
      CLICK_EDIT_PROFILE_OTP_RESET: 'click_edit_profile_otp_reset_web',
      CLICK_EDIT_PROFILE_OTP_SUBMIT: 'click_edit_profile_otp_submit_web',
    },
    ON_CHANGE: {
      ON_CHANGE_FAVORITE_EDIT_MARKUP: 'on_change_favorite_edit_markup_web',
      ON_CHANGE_SEARCH_ORDER: 'on_change_search_order',
      ON_CHANGE_WITHDRAW_COMMISION: 'on_change_withdraw_commision_web',
      ON_CHANGE_WITHDRAW_REFERRAL: 'on_change_withdraw_referral_web',
      ON_CHANGE_WITHDRAW_NEW_BANK_ACCOUNT_FORM: 'on_change_withdraw_new_bank_account_form_web',
      ON_CHANGE_WITHDRAW_PIN: 'on_change_withdraw_pin_web',
      ON_CHANGE_STORE_NAME: 'on_change_store_name_web',
      ON_CHANGE_STORE_LINK: 'on_change_store_link_web',
      ON_CHANGE_STORE_FB_PIXEL: 'on_change_store_fb_pixel_web',
      ON_CHANGE_STORE_ECOMMERCE_FORM: 'on_change_store_ecommerce_form_web',
      ON_CHANGE_STORE_SOCIAL_MEDIA_FORM: 'on_change_store_social_media_form_web',
      ON_CHANGE_EDIT_PROFILE: 'on_change_edit_profile_web',
      ON_CHANGE_EDIT_PROFILE_OTP: 'on_change_edit_profile_otp_web',
      ON_CHANGE_PIN_OTP: 'on_change_pin_otp_web',
      ON_CHANGE_KYC_FORM: 'on_change_kyc_form_web',
      ON_CHANGE_PRODUCT_DETAIL_FAVORITE_MARKUP: 'on_change_product_detail_favorite_markup',
      ON_CHANGE_SHARE_PRODUCT_EDIT_FORM: 'on_change_share_product_edit_form_web',
      ON_CHANGE_SEARCH_AUTOCOMPLETE: 'on_change_search_autocomplete_web',
      ON_CHANGE_SEARCH_SUPPLIER: 'on_change_search_supplier_web',
      ON_CHANGE_CART_QUANTITY: 'on_change_cart_quantity_web',
      ON_CHANGE_SEARCH_ADDRESS: 'on_change_search_address_web',
      ON_CHANGE_PREVIEW_CHECKOUT_ITEM_MARKUP: 'on_change_preview_checkout_item_markup_web',
      ON_CHANGE_PREVIEW_CHECKOUT_NOTES: 'on_change_preview_checkout_notes_web',
      ON_CHANGE_SEARCH_VOUCHER: 'on_change_search_voucher_web',
      ON_CHANGE_POTENTIAL_REWARD: 'on_change_potential_reward_web',
    },
  };

  const writeLog = async (name: string, params?: { [key: string]: any }) => {
    if (IS_LOAD_LOG) {
      let type = '';
      if (Object.values(LOGS.PAGE).indexOf(name) > -1) type = 'page';
      else if (Object.values(LOGS.CLICK).indexOf(name) > -1) type = 'click';
      else if (Object.values(LOGS.ON_CHANGE).indexOf(name) > -1) type = 'on_change';
      else {
        return;
      }

      let ids = '';
      if (Object.keys(profile || {}).length > 0) {
        ids = profile.id.toString();
      }
      // else {
      //   let temp = await getFromStorage('session-id');
      //   if (temp && temp !== null && temp.length > 0) {
      //     ids = temp;
      //   } else {
      //     temp = Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
      //       .toString(36)
      //       .slice(1);
      //     ids = temp;
      //     saveToStorage('session-id', ids);
      //   }
      // }
      if (
        process.env.NEXT_PUBLIC_EVENT_LOG_URL &&
        typeof process.env.NEXT_PUBLIC_EVENT_LOG_URL === 'string' &&
        ids.length > 0
      ) {
        axios
          .post(process.env.NEXT_PUBLIC_EVENT_LOG_URL || '', {
            user_id: Number(ids),
            product_id:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'product_id')
                ? params?.product_id
                : params !== undefined && Object.prototype.hasOwnProperty.call(params, 'product_sku')
                ? params?.id
                : -1,
            supplier_id:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'supplier_id')
                ? params?.supplier_id
                : -1,
            supplier_price:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'supplier_price')
                ? params?.supplier_price
                : -1,
            marketplace_price:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'marketplace_price')
                ? params?.marketplace_price
                : -1,
            category1:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'category1')
                ? params?.category1
                : '',
            category2:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'category2')
                ? params?.category2
                : '',
            category3:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'category3')
                ? params?.category3
                : '',
            supplier_name:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'supplier_name')
                ? params?.supplier_name
                : '',
            status:
              params !== undefined && Object.prototype.hasOwnProperty.call(params, 'status') ? params?.status : '',
            entity_name: '',
            entity_id: '',
            event_type: type,
            event_name: name,
            title: name.replaceAll('_', ' '),
            timestamp: Number(`${new Date().getTime()}000`).toString(),
            params: params
              ? {
                  ...params,
                  env: process.env.NODE_ENV,
                  id: ids,
                }
              : {
                  env: process.env.NODE_ENV,
                  id: ids,
                },
            source: {
              platform: 'webapps',
              os: navigator.userAgent,
            },
          })
          .catch((e) => console.log(e));
      }
    }
  };

  return {
    LOGS,
    writeLog,
  };
};

export default useEventLog;
