/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */

import { postCheckout, postPreCheckout } from '@/lib/api/order/checkout';
import { useAppDispatch, useAppSelector } from '@/store';
import { removeAddressIntro } from '@/store/address_intro/action';
import { removePenalty } from '@/store/penalty/action';
import { removePreCheckoutInit, savePreCheckoutInit } from '@/store/pre_checkout_init/action';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useLayout } from '../../utils';
import { removePreCheckoutRequest, savePreCheckoutRequest } from '@/store/pre_checkout_request/action';
import { removePreCheckout, savePreCheckout } from '@/store/pre_checkout/action';

const usePreCheckout = () => {
  // API
  const {
    mutate: mutationPreCheckout,
    isError: isErrorPreCheckout,
    isLoading: isPreCheckoutLoading,
    reset: resetStatePreCheckout,
  } = useMutation(postPreCheckout);
  const {
    mutate: mutationCheckout,
    isError: isErrorCheckout,
    isLoading: isLoadingCheckout,
    reset: resetStateCheckout,
  } = useMutation(postCheckout);

  const { goBack } = useLayout();
  const dispatch = useAppDispatch();
  const preCheckoutInitData = useAppSelector((state) => state.PreCheckoutInit.data);
  const preCheckoutRequestData = useAppSelector((state) => state.PreCheckoutRequest.data);
  const preCheckoutData = useAppSelector((state) => state.PreCheckout.data);
  const [preCheckoutInit, setPreCheckoutInit] = useState<Order.PreCheckoutInit>();
  const [preCheckoutReq, setPrecheckoutReq] = useState<Order.PreCheckoutRequestProps>({
    destination: null,
    orders: [],
    selected_payment_channel: {},
    trx_vouchers: [],
  });
  const [preCheckout, setPrecheckout] = useState<Order.PreCheckoutResponseProps>();
  const [activeSupplier, setActiveSupplier] = useState<number>(0);

  const onPostPreCheckout = (request: Order.PreCheckoutRequestProps) => {
    mutationPreCheckout(request, {
      onSuccess: (response) => {
        if (response !== undefined) {
          setPrecheckout(response?.data);
        }
      },
      onError() {
        resetStatePreCheckout();
      },
    });
  };

  const onPostCheckout = (
    request: Order.PreCheckoutRequestProps,
    callback: (response: { data: Order.CheckoutResponseProps; message_client: string }) => void,
  ) => {
    mutationCheckout(request, {
      onSuccess: (response) => {
        if (response !== undefined) {
          dispatch(removePenalty());
          dispatch(removeAddressIntro());
          dispatch(removePreCheckoutInit());
          dispatch(removePreCheckoutRequest());
          dispatch(removePreCheckout());

          callback(response);
        }
      },
      onError: () => {
        resetStateCheckout();
      },
    });
  };

  // Update Pre Checkout Init
  const updatePreCheckoutInit = (data: Order.PreCheckoutInit) => {
    setPreCheckoutInit(data);
  };

  // Update Pre Checkout Request
  const updatePreCheckoutRequest = (data: any) => {
    setPrecheckoutReq(data);
  };

  // Set Order Data
  const updatePreCheckout = (data: Order.PreCheckoutResponseProps) => {
    setPrecheckout(data);
  };

  // Get Total Price Supplier
  const getTotalPriceSupplier = (supplierIndex: number) => {
    let total = 0;
    preCheckout?.orders?.[supplierIndex].items?.forEach((it) => {
      total += it.reseller_price * it.quantity;
    });
    return total;
  };

  // Get Total Price Supplier
  const getTotalCommisionSupplier = (supplierIndex: number) => {
    let total = 0;
    preCheckout?.orders?.[supplierIndex].items?.forEach((it) => {
      total +=
        it.reseller_price - it.marketplace_price > 0 ? (it.reseller_price - it.marketplace_price) * it.quantity : 0;
    });
    return total;
  };

  // Get Total Price Supplier
  const getTotalCommision = () => {
    let total = 0;
    preCheckout?.orders?.forEach((it) => {
      it.items?.forEach((it) => {
        total +=
          it.reseller_price - it.marketplace_price > 0 ? (it.reseller_price - it.marketplace_price) * it.quantity : 0;
      });
    });
    return total;
  };

  // Set Order Active
  const setOrderActive = (index: number) => {
    setActiveSupplier(index);
  };

  const loadPreCheckoutInit = () => {
    if (preCheckoutInitData) {
      updatePreCheckoutInit(preCheckoutInitData);
      return preCheckoutInitData;
    }
  };

  const loadPreCheckoutInitWithBack = () => {
    if (preCheckoutInitData) {
      updatePreCheckoutInit(preCheckoutInitData);
      return preCheckoutInitData;
    } else goBack();
  };

  const updateTempPreCheckoutInit = (data: Order.PreCheckoutInit) => {
    dispatch(savePreCheckoutInit(data));
  };

  const removeTempPreCheckoutInit = () => {
    dispatch(removePreCheckoutInit());
  };

  const loadPreCheckoutRequest = () => {
    if (preCheckoutRequestData) {
      updatePreCheckoutRequest(preCheckoutRequestData);
      return preCheckoutRequestData;
    }
  };

  const updateTempPreCheckoutRequest = (data: Order.PreCheckoutRequestProps) => {
    dispatch(savePreCheckoutRequest(data));
  };

  const removeTempPreCheckoutRequest = () => {
    dispatch(removePreCheckoutRequest());
  };

  const loadPreCheckout = () => {
    if (preCheckoutData) {
      updatePreCheckout(preCheckoutData);
      return preCheckoutData;
    }
  };

  const updateTempPreCheckout = (data: Order.PreCheckoutResponseProps) => {
    dispatch(savePreCheckout(data));
  };

  const removeTempPreCheckout = () => {
    dispatch(removePreCheckout());
  };

  return {
    preCheckoutInit,
    preCheckoutReq,
    preCheckout,
    activeSupplier,
    updatePreCheckoutInit,
    updatePreCheckoutRequest,
    updatePreCheckout,
    loadPreCheckoutInit,
    loadPreCheckoutInitWithBack,
    loadPreCheckoutRequest,
    loadPreCheckout,
    getTotalPriceSupplier,
    getTotalCommisionSupplier,
    getTotalCommision,
    updateTempPreCheckoutInit,
    removeTempPreCheckoutInit,
    removeTempPreCheckoutRequest,
    updateTempPreCheckoutRequest,
    updateTempPreCheckout,
    removeTempPreCheckout,
    setOrderActive,
    onPostPreCheckout,
    onPostCheckout,
    isErrorPreCheckout,
    isErrorCheckout,
    isLoadingCheckout,
    isPreCheckoutLoading,
  };
};

export default usePreCheckout;
